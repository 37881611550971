.ui.form .field > label {
	font-size: 14px;
	letter-spacing: 0.1em;
}

#engagement {
	transform: translate3d(-102px, 0px, 0px);
}

.no-underline {
	text-decoration: none !important;
}

/* Quill Text Editor */
.ql-snow .ql-picker.ql-header {
	width: 100px;
}

.ql-snow .ql-picker {
	height: 28px;
	bottom: 2px;
}

.ql-editor {
	min-height: 300px;
}

.ql-toolbar.ql-snow {
	border: 1px solid #0b2f4d !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
	border-top: 0px !important;
}

.ql-container.ql-snow {
	border: 1px solid #0b2f4d !important;
}

.ql-editor.ql-blank::before {
	font-style: normal !important;
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 16px !important;
	line-height: 20px !important;
	letter-spacing: -0.01em;
	color: #8a9299 !important;
}

.ql-editor p {
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 16px !important;
	line-height: 20px !important;
	letter-spacing: -0.01em;
	color: #0b2f4d !important;
}

/* End of Quill Text Editor */

.ui.container.container-news {
	margin-top: 85px !important;
}

.mark {
	padding: 0.2em;
	background-color: #fcf8e3;
	text-align: center;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.featured-image {
	width: 100%;
	max-height: 500px;
	object-fit: cover;
}

.display-title {
	font-size: 85px !important;
	line-height: 9rem !important;
}

.fr {
	float: right;
}

.ui.menu:not(.vertical) .item {
	align-items: flex-start !important;
	display: block !important;
}

.ui.secondary.menu .item {
	align-self: auto !important;
}

@media (max-width: 767px) {
	.centered-grid {
		align-items: center;
		display: flex !important;
		flex-direction: column;
	}
}
