.footer {
	background-color: #0B2F4D;
	padding-top: 80px;
	padding-bottom: 30px;
}

.footer h4 {
	color: #fff !important;
}

.footer p {
	color: #fff !important;
}

.footer-navigation a {
	color: #fff !important;
	text-decoration-line: none !important;
	font-family: 'neue-haas-unica' !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 24px !important;
}

.footer-navigation a:hover {
	color: #d07430;
}

.footer-navigation p {
	margin-bottom: 12px !important;
}

p.blue-400 {
	color: #83A8C9 !important;
}

.space-between {
	display: flex !important; 
	flex-direction: column; 
	justify-content: space-between;
}

.width-120px {
	width: 120px; 
}

.mw-240px {
	max-width: 240px !important; 
}

.mw-192px {
	max-width: 192px !important;
}

.footer .license-mobile {
	display: none !important;
}

@media screen and (max-width: 500px) { /* mobile */
	.mr-40-30-40 {
		margin-right: 40px; 
	}
	.footer {
		padding-top: 40px;
		padding-bottom: 10px;
	}
}
@media screen and (min-width: 501px) and (max-width: 991px) { /* tablet */
	.mr-40-30-40 {
		margin-right: 30px;
	}
	.footer {
		padding-top: 80px;
		padding-bottom: 10px;
	}
}
@media screen and (min-width: 992px) { /* computer, including small screens like laptops */
	.mr-40-30-40 {
		margin-right: 40px; 
	}
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
	.ui.container.footer-container {
		width: auto !important;
		min-width: 714px !important;
		margin-left: 40px !important; 
		margin-right: 40px !important;
	}
}

@media screen and (min-width: 834px) {
	.footer-mobile {
		display: none !important; 
	}
}

@media screen and (max-width: 833px) {
	.footer-tablet-computer {
		display: none !important; 
	}
}

@media screen and (max-width: 767px) {
	.footer .hr2 {
		margin-top: 40px !important; 
	}
	.footer .list {
		display: flex !important;
		justify-content: space-between !important;
		width: 100% !important;
		max-width: 350px !important;
		min-width: 350px !important;
	}
	.footer .donate-footer.mt-40  {
		margin-top: unset !important;
		margin-bottom: 40px !important;
	}
	.footer .license-computer {
		display: none !important;
	}
	.footer .license-mobile {
		display: unset !important;
	}
}

