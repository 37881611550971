.staff-section:hover {
	background-color: #ebebeb;
}

@media screen and (max-width: 752px) {
	.center {
		text-align: center !important;
	}
}

.round-image {
	border-radius: 50%;
}