@media screen and (min-width: 770px) {
    .cross-condition-community-subtitle-paragraph {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        text-align: center;
    }
    .cross-condition-burden-of-disease-community-mq1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
    .cross-condition-burden-of-disease-community-mq2 {
        margin: 0 auto; 
    }
    .cross-condition-community-subtitle-paragraph .max-width-680px {
        max-width: 680px !important;
    }
}

