#programsAndResources div.four.wide.computer.sixteen.wide.mobile.eight.wide.tablet.column {
	margin-bottom: 80px !important;
}
.mw-608px {
    max-width: 608px !important;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    #programsAndResources div.four.wide.computer.sixteen.wide.mobile.eight.wide.tablet.column {
        margin-bottom: 60px !important;
    }
}
@media screen and (max-width: 767px) {
    #programsAndResources div.four.wide.computer.sixteen.wide.mobile.eight.wide.tablet.column {
        margin-bottom: 40px !important;
    }
}