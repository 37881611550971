#GARegistry .banner-with-text {
	position: relative;
    width: 100vw;
    max-height: 600px;
    overflow: hidden;
    margin: 0 auto;
}

#GARegistry .banner-text {
	position: absolute;
    left: 4%; 
    bottom: 13%;
}

#GARegistry .banner-computer-tablet {
	width: 100%;
}

#GARegistry .banner-mobile {
	width: 100%;
}

#GARegistry .first-paragraph {
    max-width: 415px; 
}

#GARegistry .diagram {
    width: 100%;
    max-width: 760px; 
}

#GARegistry .h3 {
    max-width: 85% !important;
}

#GARegistry .padding-0 {
    padding: 0 !important;
}

#GARegistry .mb-0 {
    margin-bottom: 0 !important;
}

#GARegistry .subtitle.third-section {
    width: unset;
}

#GARegistry .pb-15 {
    padding-bottom: 15px;
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
	#GARegistry .ui.container.container-ga-registry {
		width: auto !important;
		min-width: 714px !important;
		margin-left: 40px !important; 
		margin-right: 40px !important;
	}
}

@media screen and (max-width: 500px) { /* mobile */
    #GARegistry .first-paragraph {
        max-width: none; 
    }
    #GARegistry .subtitle {
        width: 250px;
    }
    #GARegistry .white-text {
        color: unset !important; 
    }
    #GARegistry .banner-text {
        position: relative;
        margin-top: 40px;
    }
    #GARegistry .h3 {
        max-width: unset !important;
    }
    #GARegistry .button-orange-500 {
        padding: 7px 15px 11px !important;
    }
    #GARegistry .computer-tablet-only {
        display: none; 
    }
}

@media screen and (min-width: 501px) and (max-width: 991px) { /* tablet */
    #GARegistry .first-paragraph {
        max-width: none; 
    }
    #GARegistry .subtitle {
        width: 250px;
    }
    #GARegistry .mobile-only {
        display: none;
    }
}

@media screen and (min-width: 992px) { /* computer, including small screens like laptops */
    #GARegistry .mobile-only {
        display: none;
    }
}