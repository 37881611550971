.popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 50%;
    padding: 25px !important;
    width: 300px;
}

.popup-arrow {
    color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}

.my-popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 50%;
    padding: 5px;
}

.my-popup-arrow {
    color: rgb(255, 255, 255);
}

[role='tooltip'].my-popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.my-popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].my-popup-overlay {
    background: transparent;
}

.popup-content {
    border-radius: 5px;
    padding: 0.5rem;
}

html[data-theme='dark'] .popup-content {
    background-color: rgb(41, 45, 62);
    color: #fff;
    border: 1px solid #9a9595;
}

html[data-theme='light'] .popup-content {
    background-color: #fff;
    color: #000;
    border: 1px solid #d7d7d7;
}

html[data-theme='dark'] .popup-arrow>svg {
    color: rgb(41, 45, 62);
    stroke-width: 2px;
    stroke: #9a9595;
    stroke-dasharray: 30px;
    stroke-dashoffset: -54px;
}

html[data-theme='light'] .popup-arrow>svg {
    color: #fff;
    stroke-width: 2px;
    stroke: #d7d7d7;
    stroke-dasharray: 30px;
    stroke-dashoffset: -54px;
}

/* index.css */
.modal {
    font-size: 12px;
}

.modal>.header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}

.modal>.content {
    width: 100%;
    padding: 10px 5px;
}

.modal>.actions {
    margin: auto;
}

.modal>.actions {
    width: 100%;
    padding: 10px 5px;
    text-align: center;
}

.modal>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

.popup-menu {
    width: 200px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
  }
  .popup-menu-item {
    cursor: pointer;
    padding: 5px;
    height: 28px;
    border-bottom: 1px solid rgb(187, 187, 187);
  }
  .popup-menu-item:hover {
    color: #2980b9;
  }