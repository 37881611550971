#navigation-atlas {
  position: relative;
  width: 980px;
  height: 649px;
  margin: 0 auto;
}

#navigation-atlas li {
  list-style: none;
  position: absolute;
  display: inline-block;
  height: 44px;
  padding: 0 10px;
  border: solid 3px #fff;
  border-radius: 11px;
  text-align: center;
  text-decoration: none;
  background: #ff710a;
  transition: background 0.3s ease-in-out;
  font-size: 13px;
  line-height: 18px;
  color: #fff;
  font-weight: 700 !important;
}

#navigation-atlas .menu-1077 {
  top: 52px;
  left: 273px;
  width: 105px;
}

#navigation-atlas .menu-1078 {
  top: 127px;
  left: 443px;
  width: 130px;
}

#navigation-atlas .menu-1084 {
  top: 383px;
  left: 6px;
  width: 160px;
}

#navigation-atlas .menu-1082 {
  top: 330px;
  left: 564px;
  width: 170px;
}

#navigation-atlas .menu-1075 {
  top: 254px;
  right: 34px;
  width: 175px;
}

#navigation-atlas .menu-1076 {
  top: 155px;
  left: 8px;
  width: 180px;
}

#navigation-atlas .menu-1083 {
  top: 61px;
  left: 695px;
  width: 145px;
}

#navigation-atlas .menu-1085 {
  top: 417px;
  left: 514px;
  width: 185px;
}

#navigation-atlas .menu-1080 {
  top: 460px;
  left: 335px;
  width: 125px;
}

#navigation-atlas .menu-1081 {
  top: 502px;
  right: 7px;
  width: 110px;
}