.tab-menu.genetic-alliance-studies .ui.segment {
	border: 0 !important;
	box-shadow: none !important;
    font-size: unset !important;
}

.tab-menu.genetic-alliance-studies .ui.secondary.pointing.menu {
	border-bottom: 0 !important;
}

.tab-menu.genetic-alliance-studies .ui.secondary.pointing.menu .active.item {
	border-bottom: 4px solid #E4772A !important;
}

.ui.secondary.pointing.menu a {
	font-family: 'neue-haas-unica' !important;
	font-size: 36px !important;
	font-weight: 500 !important;
	line-height: 40px !important;
	letter-spacing: -0.05em;
	color: #8A9299 !important;
	text-decoration-line: none !important;
	padding-top: 0 !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
	padding-bottom: 15px !important;
}

.tab-menu.genetic-alliance-studies .ui.secondary.pointing.menu .active.item span {
	color: #0B2F4D !important;
}

/* .tab-menu.genetic-alliance-studies .ui.secondary.pointing.menu {
	flex-wrap: wrap !important;
} */

.tab-menu.genetic-alliance-studies .ui.segment.active.tab {
    padding: 0 !important;
}

.tab-menu.genetic-alliance-studies .ui.segment.active.tab .ui.container {
    margin: 0 !important;
}

@media screen and (max-width: 450px) { 
    /* mobile font */
    h3.tab-menu.genetic-alliance-studies {
        font-family: 'neue-haas-unica' !important;
        font-size: 30px !important;
        font-weight: 500 !important;
        line-height: 32px !important;
        letter-spacing: -0.04em;
        color: #0B2F4D !important;
    }
    /* .tab-menu.genetic-alliance-studies .ui.secondary.pointing.menu a {
		font-family: 'neue-haas-unica' !important;
		font-size: 20px !important;
		font-weight: 500 !important;
		line-height: 32px !important;
		letter-spacing: -0.04em;
		text-decoration-line: none !important;
	} */
    .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu .item {
        width: fit-content; 
        max-width: 500px;
        margin-bottom: 15px; 
    }

    .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu {
		width: 345px !important;
	}

    /* adjust according to number of tabs */
    .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu .item:nth-child(2) {
        margin-bottom: 15px !important;
        width: fit-content !important;
    }
    /* .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu .item:nth-child(3) {
        width: fit-content !important;
    } */
    .tab-menu.genetic-alliance-studies .ui.secondary.pointing.menu {
        flex-wrap: wrap !important;
        flex-direction: row !important;
    }
    /* adjust according to number of tabs */
    .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu .item:nth-child(2) {
        margin-right: 30px !important;
    }
}

/* customize as needed */
@media only screen and (min-width: 451px) and (max-width: 1010px) {
    .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu {
		max-width: 765px !important;
	}
    /* adjust according to number of tabs */
    .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu .item:nth-child(2) {
        margin-bottom: 30px !important;
        width: fit-content !important;
    }
    /* .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu .item:nth-child(3) {
        width: fit-content !important;
    } */
    .tab-menu.genetic-alliance-studies .ui.secondary.pointing.menu {
        flex-wrap: wrap !important;
        flex-direction: row !important;
    }
    /* adjust according to number of tabs */
    .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu .item:nth-child(2) {
        margin-right: 30px !important;
    }
}

@media only screen and (min-width: 1011px) {
    /* adjust according to number of tabs */
    .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.genetic-alliance-studies .ui.pointing.secondary.menu .item:nth-child(2) {
        margin-right: 30px !important;
    }
}

/* end of tab menu */

@media screen and (min-width: 770px) {
    .genetic-alliance-studies-overview-mq1 {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
}

/* #geneticAllianceStudies .cross-condition-burden-of-disease-study-section {
    position: relative;
    width: 100vw;
    height: 516px;
    overflow: hidden;
    margin: 0 auto;
} */

/* #geneticAllianceStudies .cross-condition-section {
    background: #E1EDF7; 
    display: flex;
    flex-direction: row;
    flex-wrap: row;
    height: 516px;
    overflow: hidden;
}
#geneticAllianceStudies .cross-condition-section-div-image {
    width: 50%; 
    overflow: hidden; 
    display: flex;
}
#geneticAllianceStudies .cross-condition-section-image {
    object-fit: cover;
    width: 100%;
    min-width: 614px;
}
#geneticAllianceStudies .cross-condition-section-div-text-outer {
    width: 50%; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    flex-wrap: wrap; 
    align-content: center;
}
#geneticAllianceStudies .cross-condition-section-div-text-inner {
    margin: 60px;
}

#geneticAllianceStudies .tell-us-section {
    background: #FFC741; 
    display: flex; 
    flex-direction: row; 
    flex-wrap: row; 
    height: 516px; 
    overflow: hidden;
}
#geneticAllianceStudies .tell-us-section-div-text-outer {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
}
#geneticAllianceStudies .tell-us-section-div-text-inner {
    margin: 60px;
}
#geneticAllianceStudies .tell-us-section-div-image {
    width: 50%; 
    overflow: hidden; 
    display: flex;
}
#geneticAllianceStudies .tell-us-section-image {
    object-fit: cover; 
    width: 100%; 
    min-width: 572px;
}

@media screen and (max-width: 1200px) {
    #geneticAllianceStudies .cross-condition-section {
        background: #E1EDF7; 
        display: flex;
        flex-direction: column;
        flex-wrap: row;
        height: unset;
        overflow: hidden;
    }
    #geneticAllianceStudies .tell-us-section {
        background: #FFC741; 
        display: flex; 
        flex-direction: column-reverse; 
        flex-wrap: row; 
        height: unset; 
        overflow: hidden;
    }
    #geneticAllianceStudies .cross-condition-section-div-image {
        width: 100%; 
        overflow: hidden; 
        display: flex;
        min-height: 500px;
        max-height: 550px;
    }
    #geneticAllianceStudies .cross-condition-section-div-text-outer {
        width: 100%; 
        display: flex; 
        flex-direction: column; 
        justify-content: center; 
        flex-wrap: wrap; 
        align-content: center;
    }
    #geneticAllianceStudies .tell-us-section-div-text-outer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        align-content: center;
    }
    #geneticAllianceStudies .tell-us-section-div-image {
        width: 100%; 
        overflow: hidden; 
        display: flex;
        min-height: 500px;
        max-height: 550px;
    }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
    #geneticAllianceStudies .cross-condition-section-div-text-inner {
        margin: 40px;
    }
    #geneticAllianceStudies .tell-us-section-div-text-inner {
        margin: 40px;
    }
}

@media screen and (max-width: 500px) {
    #geneticAllianceStudies .cross-condition-section-div-text-inner {
        margin: 40px 15px 40px 15px;
    }
    #geneticAllianceStudies .tell-us-section-div-text-inner {
        margin: 40px 15px 40px 15px;
    }
} */


#geneticAllianceStudies .studies-color-block-blue-image {
    padding-right: 0; 
    padding-top: 0;
}

#geneticAllianceStudies .studies-color-block-blue-text {
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    padding-left: 60px;
}

#geneticAllianceStudies .studies-color-block-yellow-text {
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    padding-right: 60px;
}

#geneticAllianceStudies .studies-color-block-yellow-image {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#geneticAllianceStudies .studies-mw-600 {
    max-width: 600px;
}

@media screen and (max-width: 1100px) {
    #geneticAllianceStudies .studies-flex-direction-column {
        flex-direction: column;
    }
    #geneticAllianceStudies .studies-flex-direction-column.studies-yellow-section {
        flex-direction: column-reverse;
    }
    #geneticAllianceStudies .studies-yellow-section {
        padding-bottom: 25px;
    }
    #geneticAllianceStudies .studies-color-block-blue-image {
        width: 100% !important;
        max-height: 550px; 
        overflow: hidden;
        padding-right: 14px;
    }
    #geneticAllianceStudies .studies-color-block-blue-text {
        width: 100% !important;
        padding-left: 15px;
        padding-top: 40px;
    }
    #geneticAllianceStudies .studies-color-block-yellow-text {
        width: 100% !important;
        padding-right: 15px;
        padding-top: 40px;
    }
    #geneticAllianceStudies .studies-mw-600 {
        max-width: unset !important;
    }
    #geneticAllianceStudies .studies-color-block-yellow-image {
        width: 100% !important;
        max-height: 550px; 
        overflow: hidden;
        padding-top: 0px !important;
    }
    /* #geneticAllianceStudies .studies-color-block-blue-image {
        padding-right: 15px;
    } */
    #geneticAllianceStudies .studies-color-block-yellow-image {
        /* padding-left: 15px; */
        padding-top: 15px;
    }
    #geneticAllianceStudies .studies-margin-left-right {
        margin-left: 0px !important; 
        margin-right: 0px !important;
    }
    #geneticAllianceStudies .studies-blue-section {
        padding-bottom: 40px;
    }
}

@media screen and (max-width: 767px) {
    #geneticAllianceStudies .studies-color-block-blue-text {
        padding-left: 40px; 
        padding-right: 40px;
    }
    #geneticAllianceStudies .studies-color-block-yellow-text {
        padding-left: 40px; 
        padding-right: 40px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1100px) {
    #geneticAllianceStudies .studies-color-block-blue-text {
        padding-left: 54px; 
        padding-right: 54px;
    }
    #geneticAllianceStudies .studies-color-block-yellow-text {
        padding-left: 54px; 
        padding-right: 54px;
    }
}
