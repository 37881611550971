#policy .field-title {
    margin-bottom: 0px;
}

#policy .ui.container {
    margin-bottom: 0px !important; 
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    #policy .ui.container {
        width: unset;
    }
}