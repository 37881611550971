.rule-orange-500 {
	border: 1px solid #E4772A;
}

.mw-600 {
    max-width: 600px;
}

/* .center {
    margin: auto !important;
} */

@media screen and (max-width: 768px) {
    p {
        text-align: left;
    }
    .mt-100 {
        margin-top: 50px !important; 
    }
    .mb-100 {
        margin-bottom: 50px !important; 
    }
}