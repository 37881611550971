@media screen and (min-width: 992px) {
    #fullViewComputer {
        position: relative;
        width: 864px;
        height: 994px;
        margin: 0 auto;
    }
    #fullViewComputer li {
        list-style: none;
        position: absolute; 
        /* border: 1px solid blue; */
    }
    #netsFullView .show-full-screen {
        display: none;
    }
    .footnote.full-view-computer {
        width: 864px; 
        margin: 0 auto; 
    }
    
    
    #fullViewComputer .menu-1 {
        top: 72px;
        left: 117px;
        width: 57px;
        height: 27px;
    }
    #fullViewComputer .menu-2 {
        top: 79px;
        left: 218px;
        width: 57px;
        height: 14px;
    }
    #fullViewComputer .menu-3 {
        top: 69px;
        left: 314px;
        width: 95px;
        height: 34px;
    }
    #fullViewComputer .menu-4 {
        top: 69px;
        left: 442px;
        width: 67px;
        height: 34px;
    }
    #fullViewComputer .menu-5 {
        top: 141px;
        left: 116px;
        width: 59px;
        height: 32px;
    }
    #fullViewComputer .menu-6 {
        top: 140px;
        left: 280px;
        width: 46px;
        height: 12px;
    }
    #fullViewComputer .menu-7 {
        top: 140px;
        left: 344px;
        width: 38px;
        height: 27px;
    }
    #fullViewComputer .menu-8 {
        top: 140px;
        left: 409px;
        width: 53px;
        height: 28px;
    }
    #fullViewComputer .menu-9 {
        top: 198px;
        left: 106px;
        width: 93px;
        height: 20px;
    }
    #fullViewComputer .menu-10 {
        top: 201px;
        left: 334px;
        width: 57px;
        height: 15px;
    }
    #fullViewComputer .menu-11 {
        top: 201px;
        left: 432px;
        width: 61px;
        height: 15px;
    }
    
    
    
    
    
    #fullViewComputer .menu-12 {
        top: 77px;
        left: 624px;
        width: 69px;
        height: 28px;
    }
    #fullViewComputer .menu-13 {
        top: 80px;
        left: 718px;
        width: 51px;
        height: 15px;
    }
    #fullViewComputer .menu-14 {
        top: 130px;
        left: 686px;
        width: 72px;
        height: 22px;
    }
    #fullViewComputer .menu-15 {
        top: 151px;
        left: 597px;
        width: 60px;
        height: 26px;
    }
    #fullViewComputer .menu-16 {
        top: 234px;
        left: 613px;
        width: 36px;
        height: 13px;
    }
    #fullViewComputer .menu-17 {
        top: 231px;
        left: 727px;
        width: 33px;
        height: 21px;
    }
    #fullViewComputer .menu-18 {
        top: 294px;
        left: 564px;
        width: 57px;
        height: 28px;
    }
    #fullViewComputer .menu-19 {
        top: 286px;
        left: 706px;
        width: 65px;
        height: 40px;
    }
    
    
    
    #fullViewComputer .menu-20 {
        top: 360px;
        left: 618px;
        width: 94px;
        height: 21px;
    }
    #fullViewComputer .menu-21 {
        top: 422px;
        left: 628px;
        width: 77px;
        height: 40px;
    }
    #fullViewComputer .menu-22 {
        top: 469px;
        left: 613px;
        width: 104px;
        height: 41px;
    }
    #fullViewComputer .menu-23 {
        top: 518px;
        left: 625px;
        width: 83px;
        height: 13px;
    }
    #fullViewComputer .menu-24 {
        top: 568px;
        left: 574px;
        width: 55px;
        height: 28px;
    }
    #fullViewComputer .menu-25 {
        top: 568px;
        left: 660px;
        width: 79px;
        height: 28px;
    }
    #fullViewComputer .menu-26 {
        top: 617px;
        left: 584px;
        width: 33px;
        height: 15px;
    }
    #fullViewComputer .menu-27 {
        top: 617px;
        left: 635px;
        width: 27px;
        height: 14px;
    }
    #fullViewComputer .menu-28 {
        top: 612px;
        left: 674px;
        width: 76px;
        height: 29px;
    }
    #fullViewComputer .menu-29 {
        top: 667px;
        left: 612px;
        width: 96px;
        height: 48px;
    }
    #fullViewComputer .menu-30 {
        top: 729px;
        left: 603px;
        width: 107px;
        height: 15px;
    }
    #fullViewComputer .menu-31 {
        top: 761px;
        left: 622px;
        width: 76px;
        height: 27px;
    }
    #fullViewComputer .menu-32 {
        top: 816px;
        left: 563px;
        width: 40px;
        height: 28px;
    }
    #fullViewComputer .menu-33 {
        top: 815px;
        left: 623px;
        width: 74px;
        height: 16px;
    }
    #fullViewComputer .menu-34 {
        top: 817px;
        left: 710px;
        width: 55px;
        height: 43px;
    }
    #fullViewComputer .menu-35 {
        top: 865px;
        left: 613px;
        width: 97px;
        height: 29px;
    }
    
    
    
    #fullViewComputer .menu-36 {
        top: 530px;
        left: 389px;
        width: 65px;
        height: 31px;
    }
    #fullViewComputer .menu-37 {
        top: 578px;
        left: 384px;
        width: 75px;
        height: 40px;
    }
    #fullViewComputer .menu-38 {
        top: 636px;
        left: 392px;
        width: 59px;
        height: 13px;
    }
    #fullViewComputer .menu-39 {
        top: 632px;
        left: 466px;
        width: 53px;
        height: 27px;
    }
    #fullViewComputer .menu-40 {
        top: 728px;
        left: 344px;
        width: 59px;
        height: 29px;
    }
    #fullViewComputer .menu-41 {
        top: 788px;
        left: 446px;
        width: 64px;
        height: 30px;
    }
    
    
    
    #fullViewComputer .menu-42 {
        top: 796px;
        left: 232px;
        width: 87px;
        height: 30px;
    }
    #fullViewComputer .menu-43 {
        top: 830px;
        left: 310px;
        width: 79px;
        height: 40px;
    }
    #fullViewComputer .menu-44 {
        top: 873px;
        left: 233px;
        width: 98px;
        height: 27px;
    }
    
    
    
    #fullViewComputer .menu-45 {
        top: 262px;
        left: 119px;
        width: 60px;
        height: 29px;
    }
    #fullViewComputer .menu-46 {
        top: 262px;
        left: 230px;
        width: 42px;
        height: 29px;
    }
    #fullViewComputer .menu-47 {
        top: 266px;
        left: 331px;
        width: 85px;
        height: 19px;
    }
    #fullViewComputer .menu-48 {
        top: 266px;
        left: 431px;
        width: 63px;
        height: 19px;
    }
    #fullViewComputer .menu-49 {
        top: 313px;
        left: 118px;
        width: 63px;
        height: 16px;
    }
    #fullViewComputer .menu-50 {
        top: 312px;
        left: 208px;
        width: 87px;
        height: 30px;
    }
    #fullViewComputer .menu-51 {
        top: 304px;
        left: 332px;
        width: 84px;
        height: 19px;
    }
    #fullViewComputer .menu-52 {
        top: 360px;
        left: 118px;
        width: 63px;
        height: 13px;
    }
    #fullViewComputer .menu-53 {
        top: 369px;
        left: 214px;
        width: 75px;
        height: 42px;
    }
    #fullViewComputer .menu-54 {
        top: 342px;
        left: 337px;
        width: 72px;
        height: 19px;
    }
    #fullViewComputer .menu-55 {
        top: 330px;
        left: 430px;
        width: 82px;
        height: 30px;
    }
    #fullViewComputer .menu-56 {
        top: 415px;
        left: 126px;
        width: 47px;
        height: 25px;
    }
    #fullViewComputer .menu-57 {
        top: 417px;
        left: 243px;
        width: 57px;
        height: 26px;
    }
    #fullViewComputer .menu-58 {
        top: 393px;
        left: 444px;
        width: 53px;
        height: 26px;
    }
    #fullViewComputer .menu-59 {
        top: 448px;
        left: 439px;
        width: 62px;
        height: 42px;
    }
    #fullViewComputer .menu-60 {
        top: 492px;
        left: 210px;
        width: 82px;
        height: 28px;
    }
    #fullViewComputer .menu-61 {
        top: 536px;
        left: 213px;
        width: 76px;
        height: 42px;
    }
    #fullViewComputer .menu-62 {
        top: 583px;
        left: 110px;
        width: 81px;
        height: 28px;
    }
    #fullViewComputer .menu-63 {
        top: 599px;
        left: 213px;
        width: 76px;
        height: 41px;
    }
    #fullViewComputer .menu-64 {
        top: 661px;
        left: 107px;
        width: 82px;
        height: 30px;
    }
    #fullViewComputer .menu-65 {
        top: 661px;
        left: 213px;
        width: 76px;
        height: 42px;
    }
    #fullViewComputer .menu-66 {
        top: 734px;
        left: 217px;
        width: 73px;
        height: 28px;
    }
    #fullViewComputer .menu-67 {
        top: 796px;
        left: 125px;
        width: 47px;
        height: 27px;
    }

    #fullViewComputer .menu-68 {
        top: 386px;
        left: 341px;
        width: 67px;
        height: 41px;
    }
    #fullViewComputer .menu-69 {
        top: 462px;
        left: 345px;
        width: 59px;
        height: 15px;
    }
    #fullViewComputer .purified-protein {
        top: 49px;
        left: 574px;
        width: 43px;
        height: 27px;
    }
    #fullViewComputer .nbe-development {
        top: 49px;
        left: 655px;
        width: 92px;
        height: 17px;
    }
}




@media screen and (min-width: 845px) and (max-width: 991px) {
    #fullViewTablet {
        position: relative;
        width: 750px;
        height: 863px;
        margin: 0 auto;
    }
    #fullViewTablet li {
        list-style: none;
        position: absolute; 
        /* border: 1px solid blue; */
    }
    #netsFullView .show-full-screen {
        display: none;
    }
    .footnote.full-view-tablet {
        width: 750px; 
        margin: 0 auto; 
    }
    
    
    
    #fullViewTablet .menu-1 {
        top: 63px;
        left: 102px;
        width: 50px;
        height: 22px;
    }
    #fullViewTablet .menu-2 {
        top: 69px;
        left: 189px;
        width: 50px;
        height: 11px;
    }
    #fullViewTablet .menu-3 {
        top: 60px;
        left: 273px;
        width: 83px;
        height: 29px;
    }
    #fullViewTablet .menu-4 {
        top: 60px;
        left: 384px;
        width: 58px;
        height: 29px;
    }
    #fullViewTablet .menu-5 {
        top: 122px;
        left: 101px;
        width: 51px;
        height: 28px;
    }
    #fullViewTablet .menu-6 {
        top: 121px;
        left: 243px;
        width: 40px;
        height: 11px;
    }
    #fullViewTablet .menu-7 {
        top: 122px;
        left: 298px;
        width: 34px;
        height: 23px;
    }
    #fullViewTablet .menu-8 {
        top: 122px;
        left: 356px;
        width: 44px;
        height: 24px;
    }
    #fullViewTablet .menu-9 {
        top: 173px;
        left: 92px;
        width: 81px;
        height: 17px;
    }
    #fullViewTablet .menu-10 {
        top: 175px;
        left: 290px;
        width: 49px;
        height: 13px;
    }
    #fullViewTablet .menu-11 {
        top: 175px;
        left: 375px;
        width: 52px;
        height: 13px;
    }
    
    
    
    
    
    
    #fullViewTablet .menu-12 {
        top: 66px;
        left: 542px;
        width: 59px;
        height: 25px;
    }
    #fullViewTablet .menu-13 {
        top: 69px;
        left: 623px;
        width: 45px;
        height: 14px;
    }
    #fullViewTablet .menu-14 {
        top: 112px;
        left: 594px;
        width: 64px;
        height: 21px;
    }
    #fullViewTablet .menu-15 {
        top: 130px;
        left: 518px;
        width: 52px;
        height: 23px;
    }
    #fullViewTablet .menu-16 {
        top: 203px;
        left: 533px;
        width: 30px;
        height: 11px;
    }
    #fullViewTablet .menu-17 {
        top: 201px;
        left: 631px;
        width: 29px;
        height: 17px;
    }
    #fullViewTablet .menu-18 {
        top: 255px;
        left: 489px;
        width: 50px;
        height: 25px;
    }
    #fullViewTablet .menu-19 {
        top: 247px;
        left: 613px;
        width: 55px;
        height: 36px;
    }
    
    
    
    
    #fullViewTablet .menu-20 {
        top: 312px;
        left: 536px;
        width: 82px;
        height: 19px;
    }
    #fullViewTablet .menu-21 {
        top: 367px;
        left: 545px;
        width: 67px;
        height: 34px;
    }
    #fullViewTablet .menu-22 {
        top: 408px;
        left: 533px;
        width: 89px;
        height: 36px;
    }
    #fullViewTablet .menu-23 {
        top: 449px;
        left: 542px;
        width: 73px;
        height: 12px;
    }
    #fullViewTablet .menu-24 {
        top: 493px;
        left: 498px;
        width: 47px;
        height: 24px;
    }
    #fullViewTablet .menu-25 {
        top: 493px;
        left: 573px;
        width: 68px;
        height: 24px;
    }
    #fullViewTablet .menu-26 {
        top: 535px;
        left: 506px;
        width: 30px;
        height: 13px;
    }
    #fullViewTablet .menu-27 {
        top: 535px;
        left: 551px;
        width: 24px;
        height: 12px;
    }
    #fullViewTablet .menu-28 {
        top: 531px;
        left: 585px;
        width: 66px;
        height: 25px;
    }
    #fullViewTablet .menu-29 {
        top: 579px;
        left: 531px;
        width: 83px;
        height: 42px;
    }
    #fullViewTablet .menu-30 {
        top: 633px;
        left: 524px;
        width: 92px;
        height: 13px;
    }
    #fullViewTablet .menu-31 {
        top: 661px;
        left: 540px;
        width: 65px;
        height: 23px;
    }
    #fullViewTablet .menu-32 {
        top: 708px;
        left: 489px;
        width: 35px;
        height: 24px;
    }
    #fullViewTablet .menu-33 {
        top: 708px;
        left: 541px;
        width: 63px;
        height: 14px;
    }
    #fullViewTablet .menu-34 {
        top: 710px;
        left: 616px;
        width: 48px;
        height: 37px;
    }
    #fullViewTablet .menu-35 {
        top: 751px;
        left: 532px;
        width: 84px;
        height: 25px;
    }
    
    
    
    
    #fullViewTablet .menu-36 {
        top: 459px;
        left: 337px;
        width: 57px;
        height: 28px;
    }
    #fullViewTablet .menu-37 {
        top: 501px;
        left: 333px;
        width: 65px;
        height: 35px;
    }
    #fullViewTablet .menu-38 {
        top: 552px;
        left: 340px;
        width: 52px;
        height: 11px;
    }
    #fullViewTablet .menu-39 {
        top: 549px;
        left: 405px;
        width: 45px;
        height: 22px;
    }
    #fullViewTablet .menu-40 {
        top: 632px;
        left: 299px;
        width: 50px;
        height: 25px;
    }
    #fullViewTablet .menu-41 {
        top: 684px;
        left: 386px;
        width: 58px;
        height: 27px;
    }
    
    
    
    
    #fullViewTablet .menu-42 {
        top: 691px;
        left: 201px;
        width: 76px;
        height: 26px;
    }
    #fullViewTablet .menu-43 {
        top: 721px;
        left: 269px;
        width: 69px;
        height: 35px;
    }
    #fullViewTablet .menu-44 {
        top: 757px;
        left: 201px;
        width: 87px;
        height: 25px;
    }
    
    
    
    
    #fullViewTablet .menu-45 {
        top: 227px;
        left: 103px;
        width: 53px;
        height: 25px;
    }
    #fullViewTablet .menu-46 {
        top: 227px;
        left: 200px;
        width: 37px;
        height: 25px;
    }
    #fullViewTablet .menu-47 {
        top: 230px;
        left: 287px;
        width: 74px;
        height: 18px;
    }
    #fullViewTablet .menu-48 {
        top: 230px;
        left: 374px;
        width: 55px;
        height: 18px;
    }
    #fullViewTablet .menu-49 {
        top: 271px;
        left: 102px;
        width: 55px;
        height: 14px;
    }
    #fullViewTablet .menu-50 {
        top: 272px;
        left: 180px;
        width: 76px;
        height: 25px;
    }
    #fullViewTablet .menu-51 {
        top: 263px;
        left: 288px;
        width: 74px;
        height: 17px;
    }
    #fullViewTablet .menu-52 {
        top: 313px;
        left: 102px;
        width: 55px;
        height: 12px;
    }
    #fullViewTablet .menu-53 {
        top: 320px;
        left: 186px;
        width: 65px;
        height: 37px;
    }
    #fullViewTablet .menu-54 {
        top: 297px;
        left: 292px;
        width: 63px;
        height: 17px;
    }
    #fullViewTablet .menu-55 {
        top: 286px;
        left: 373px;
        width: 71px;
        height: 26px;
    }
    #fullViewTablet .menu-56 {
        top: 359px;
        left: 109px;
        width: 42px;
        height: 24px;
    }
    #fullViewTablet .menu-57 {
        top: 361px;
        left: 211px;
        width: 49px;
        height: 23px;
    }
    #fullViewTablet .menu-58 {
        top: 341px;
        left: 385px;
        width: 47px;
        height: 23px;
    }
    #fullViewTablet .menu-59 {
        top: 388px;
        left: 381px;
        width: 54px;
        height: 37px;
    }
    #fullViewTablet .menu-60 {
        top: 426px;
        left: 182px;
        width: 72px;
        height: 25px;
    }
    #fullViewTablet .menu-61 {
        top: 465px;
        left: 185px;
        width: 67px;
        height: 37px;
    }
    #fullViewTablet .menu-62 {
        top: 506px;
        left: 95px;
        width: 71px;
        height: 26px;
    }
    #fullViewTablet .menu-63 {
        top: 519px;
        left: 185px;
        width: 66px;
        height: 36px;
    }
    #fullViewTablet .menu-64 {
        top: 574px;
        left: 93px;
        width: 72px;
        height: 25px;
    }
    #fullViewTablet .menu-65 {
        top: 574px;
        left: 185px;
        width: 67px;
        height: 36px;
    }
    #fullViewTablet .menu-66 {
        top: 638px;
        left: 188px;
        width: 64px;
        height: 24px;
    }
    #fullViewTablet .menu-67 {
        top: 690px;
        left: 108px;
        width: 42px;
        height: 24px;
    }
    
    #fullViewTablet .menu-68 {
        top: 334px;
        left: 296px;
        width: 59px;
        height: 37px;
    }
    #fullViewTablet .menu-69 {
        top: 401px;
        left: 299px;
        width: 52px;
        height: 14px;
    }
    #fullViewTablet .purified-protein {
        top: 42px;
        left: 498px;
        width: 38px;
        height: 25px;
    }
    #fullViewTablet .nbe-development {
        top: 43px;
        left: 568px;
        width: 81px;
        height: 14px;
    }
}


@media screen and (max-width: 844px) {
    #fullViewMobile {
        position: relative;
        width: 100%;
        max-width: 345px;
        height: auto;
        margin: 0 auto;
    }
    #netsFullView .show-full-screen {
        display: unset;
        position: fixed;
        top: 0;
        left: 0;
        width:100%;
        height: 100%;
        z-index: 3;
    }
    .footnote.full-view-mobile {
        max-width: 345px; 
        margin: 0 auto; 
    }
}



@media screen and (min-width: 992px) { /* computer */
    .tablet-only.full-view-tablet {
        display: none; 
    }
    .mobile-only.full-view-mobile {
        display: none; 
    }
}
@media screen and (min-width: 845px) and (max-width: 991px) { /* tablet */
    .computer-only.full-view-computer {
        display: none; 
    }
    .mobile-only.full-view-mobile {
        display: none; 
    }
}
@media screen and (max-width: 844px) { /* mobile */
    .tablet-only.full-view-tablet {
        display: none; 
    }
    .computer-only.full-view-computer {
        display: none; 
    }
    .mobile-only.full-view-mobile {
        display: unset !important;
    }
}




    #fullScreenFullView {
        position: relative;
        width: 864px;
        height: 994px;
        margin: 0 auto;
    }
    #fullScreenFullView li {
        list-style: none;
        position: absolute; 
        /* border: 1px solid blue; */
    }
    
    
    
    #fullScreenFullView .menu-1 {
        top: 72px;
        left: 117px;
        width: 57px;
        height: 27px;
    }
    #fullScreenFullView .menu-2 {
        top: 79px;
        left: 218px;
        width: 57px;
        height: 14px;
    }
    #fullScreenFullView .menu-3 {
        top: 69px;
        left: 314px;
        width: 95px;
        height: 34px;
    }
    #fullScreenFullView .menu-4 {
        top: 69px;
        left: 442px;
        width: 67px;
        height: 34px;
    }
    #fullScreenFullView .menu-5 {
        top: 141px;
        left: 116px;
        width: 59px;
        height: 32px;
    }
    #fullScreenFullView .menu-6 {
        top: 140px;
        left: 280px;
        width: 46px;
        height: 12px;
    }
    #fullScreenFullView .menu-7 {
        top: 140px;
        left: 344px;
        width: 38px;
        height: 27px;
    }
    #fullScreenFullView .menu-8 {
        top: 140px;
        left: 409px;
        width: 53px;
        height: 28px;
    }
    #fullScreenFullView .menu-9 {
        top: 198px;
        left: 106px;
        width: 93px;
        height: 20px;
    }
    #fullScreenFullView .menu-10 {
        top: 201px;
        left: 334px;
        width: 57px;
        height: 15px;
    }
    #fullScreenFullView .menu-11 {
        top: 201px;
        left: 432px;
        width: 61px;
        height: 15px;
    }
    
    
    
    
    
    #fullScreenFullView .menu-12 {
        top: 77px;
        left: 624px;
        width: 69px;
        height: 28px;
    }
    #fullScreenFullView .menu-13 {
        top: 80px;
        left: 718px;
        width: 51px;
        height: 15px;
    }
    #fullScreenFullView .menu-14 {
        top: 130px;
        left: 686px;
        width: 72px;
        height: 22px;
    }
    #fullScreenFullView .menu-15 {
        top: 151px;
        left: 597px;
        width: 60px;
        height: 26px;
    }
    #fullScreenFullView .menu-16 {
        top: 234px;
        left: 613px;
        width: 36px;
        height: 13px;
    }
    #fullScreenFullView .menu-17 {
        top: 231px;
        left: 727px;
        width: 33px;
        height: 21px;
    }
    #fullScreenFullView .menu-18 {
        top: 294px;
        left: 564px;
        width: 57px;
        height: 28px;
    }
    #fullScreenFullView .menu-19 {
        top: 286px;
        left: 706px;
        width: 65px;
        height: 40px;
    }
    
    
    
    #fullScreenFullView .menu-20 {
        top: 360px;
        left: 618px;
        width: 94px;
        height: 21px;
    }
    #fullScreenFullView .menu-21 {
        top: 422px;
        left: 628px;
        width: 77px;
        height: 40px;
    }
    #fullScreenFullView .menu-22 {
        top: 469px;
        left: 613px;
        width: 104px;
        height: 41px;
    }
    #fullScreenFullView .menu-23 {
        top: 518px;
        left: 625px;
        width: 83px;
        height: 13px;
    }
    #fullScreenFullView .menu-24 {
        top: 568px;
        left: 574px;
        width: 55px;
        height: 28px;
    }
    #fullScreenFullView .menu-25 {
        top: 568px;
        left: 660px;
        width: 79px;
        height: 28px;
    }
    #fullScreenFullView .menu-26 {
        top: 617px;
        left: 584px;
        width: 33px;
        height: 15px;
    }
    #fullScreenFullView .menu-27 {
        top: 617px;
        left: 635px;
        width: 27px;
        height: 14px;
    }
    #fullScreenFullView .menu-28 {
        top: 612px;
        left: 674px;
        width: 76px;
        height: 29px;
    }
    #fullScreenFullView .menu-29 {
        top: 667px;
        left: 612px;
        width: 96px;
        height: 48px;
    }
    #fullScreenFullView .menu-30 {
        top: 729px;
        left: 603px;
        width: 107px;
        height: 15px;
    }
    #fullScreenFullView .menu-31 {
        top: 761px;
        left: 622px;
        width: 76px;
        height: 27px;
    }
    #fullScreenFullView .menu-32 {
        top: 816px;
        left: 563px;
        width: 40px;
        height: 28px;
    }
    #fullScreenFullView .menu-33 {
        top: 815px;
        left: 623px;
        width: 74px;
        height: 16px;
    }
    #fullScreenFullView .menu-34 {
        top: 817px;
        left: 710px;
        width: 55px;
        height: 43px;
    }
    #fullScreenFullView .menu-35 {
        top: 865px;
        left: 613px;
        width: 97px;
        height: 29px;
    }
    
    
    
    #fullScreenFullView .menu-36 {
        top: 530px;
        left: 389px;
        width: 65px;
        height: 31px;
    }
    #fullScreenFullView .menu-37 {
        top: 578px;
        left: 384px;
        width: 75px;
        height: 40px;
    }
    #fullScreenFullView .menu-38 {
        top: 636px;
        left: 392px;
        width: 59px;
        height: 13px;
    }
    #fullScreenFullView .menu-39 {
        top: 632px;
        left: 466px;
        width: 53px;
        height: 27px;
    }
    #fullScreenFullView .menu-40 {
        top: 728px;
        left: 344px;
        width: 59px;
        height: 29px;
    }
    #fullScreenFullView .menu-41 {
        top: 788px;
        left: 446px;
        width: 64px;
        height: 30px;
    }
    
    
    
    #fullScreenFullView .menu-42 {
        top: 796px;
        left: 232px;
        width: 87px;
        height: 30px;
    }
    #fullScreenFullView .menu-43 {
        top: 830px;
        left: 310px;
        width: 79px;
        height: 40px;
    }
    #fullScreenFullView .menu-44 {
        top: 873px;
        left: 233px;
        width: 98px;
        height: 27px;
    }
    
    
    
    #fullScreenFullView .menu-45 {
        top: 262px;
        left: 119px;
        width: 60px;
        height: 29px;
    }
    #fullScreenFullView .menu-46 {
        top: 262px;
        left: 230px;
        width: 42px;
        height: 29px;
    }
    #fullScreenFullView .menu-47 {
        top: 266px;
        left: 331px;
        width: 85px;
        height: 19px;
    }
    #fullScreenFullView .menu-48 {
        top: 266px;
        left: 431px;
        width: 63px;
        height: 19px;
    }
    #fullScreenFullView .menu-49 {
        top: 313px;
        left: 118px;
        width: 63px;
        height: 16px;
    }
    #fullScreenFullView .menu-50 {
        top: 312px;
        left: 208px;
        width: 87px;
        height: 30px;
    }
    #fullScreenFullView .menu-51 {
        top: 304px;
        left: 332px;
        width: 84px;
        height: 19px;
    }
    #fullScreenFullView .menu-52 {
        top: 360px;
        left: 118px;
        width: 63px;
        height: 13px;
    }
    #fullScreenFullView .menu-53 {
        top: 369px;
        left: 214px;
        width: 75px;
        height: 42px;
    }
    #fullScreenFullView .menu-54 {
        top: 342px;
        left: 337px;
        width: 72px;
        height: 19px;
    }
    #fullScreenFullView .menu-55 {
        top: 330px;
        left: 430px;
        width: 82px;
        height: 30px;
    }
    #fullScreenFullView .menu-56 {
        top: 415px;
        left: 126px;
        width: 47px;
        height: 25px;
    }
    #fullScreenFullView .menu-57 {
        top: 417px;
        left: 243px;
        width: 57px;
        height: 26px;
    }
    #fullScreenFullView .menu-58 {
        top: 393px;
        left: 444px;
        width: 53px;
        height: 26px;
    }
    #fullScreenFullView .menu-59 {
        top: 448px;
        left: 439px;
        width: 62px;
        height: 42px;
    }
    #fullScreenFullView .menu-60 {
        top: 492px;
        left: 210px;
        width: 82px;
        height: 28px;
    }
    #fullScreenFullView .menu-61 {
        top: 536px;
        left: 213px;
        width: 76px;
        height: 42px;
    }
    #fullScreenFullView .menu-62 {
        top: 583px;
        left: 110px;
        width: 81px;
        height: 28px;
    }
    #fullScreenFullView .menu-63 {
        top: 599px;
        left: 213px;
        width: 76px;
        height: 41px;
    }
    #fullScreenFullView .menu-64 {
        top: 661px;
        left: 107px;
        width: 82px;
        height: 30px;
    }
    #fullScreenFullView .menu-65 {
        top: 661px;
        left: 213px;
        width: 76px;
        height: 42px;
    }
    #fullScreenFullView .menu-66 {
        top: 734px;
        left: 217px;
        width: 73px;
        height: 28px;
    }
    #fullScreenFullView .menu-67 {
        top: 796px;
        left: 125px;
        width: 47px;
        height: 27px;
    }

    #fullScreenFullView .menu-68 {
        top: 386px;
        left: 341px;
        width: 67px;
        height: 41px;
    }
    #fullScreenFullView .menu-69 {
        top: 462px;
        left: 345px;
        width: 59px;
        height: 15px;
    }
    #fullScreenFullView .purified-protein {
        top: 49px;
        left: 574px;
        width: 43px;
        height: 27px;
    }
    #fullScreenFullView .nbe-development {
        top: 49px;
        left: 655px;
        width: 92px;
        height: 17px;
    }





.hide-full-screen {
    display: none; 
}
.full-screen-exit-container {
    position: fixed !important;
    width: 36px;
    height: 36px;
    top: 40px;
    right: 30px;
    z-index: 1;
    cursor: pointer;
    background: #fff;
    border-radius: 18px;
    box-shadow: 2px 4px 10px rgb(0 0 0 / 15%);
}
.full-screen-exit-image {
    position: fixed !important;
    top: 50px;
    right: 40px;
    z-index: 1;
    cursor: pointer;
}