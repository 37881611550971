.background-grey {
  /* border: 1px solid; */
  background: #F3F3F5;
}

.annual-reports .ui.grid>.column:not(.row) {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

div.eight.wide.computer.sixteen.mobile.tablet.column.pb-0 {
  padding-bottom: 0 !important;
}