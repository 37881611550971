.tab-borderless.our-services .ui.segment {
	border: 0 !important;
	box-shadow: none !important;
}

.tab-borderless.our-services .ui.secondary.pointing.menu {
	border-bottom: 0 !important;
}

.tab-borderless.our-services .ui.secondary.pointing.menu .active.item {
	border-bottom: 4px solid #E4772A !important;
}

.ui.secondary.pointing.menu a {
	font-family: 'neue-haas-unica' !important;
	font-size: 36px !important;
	font-weight: 500 !important;
	line-height: 40px !important;
	letter-spacing: -0.05em;
	color: #8A9299 !important;
	text-decoration-line: none !important;
	padding-top: 0 !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
	padding-bottom: 15px !important;

}

.tab-borderless.our-services .ui.secondary.pointing.menu .active.item span {
	color: #0B2F4D !important;
}

.icon-engagement {
	fill: #A4ACB2 !important;
}

.active.item .icon-engagement {
	fill: #83A8C9 !important;
}

.icon-training-1 {
	fill: #707980 !important;
}

.active.item .icon-training-1 {
	fill: #003664 !important;
}

.icon-training-2 {
	fill: #A4ACB3 !important;
}

.active.item .icon-training-2 {
	fill: #145B90 !important;
}

.icon-training-3 {
	fill: #DCE2E6 !important;
}

.active.item .icon-training-3 {
	fill: #83A8C9 !important;
}

.icon-research {
	fill: #A4ACB3 !important;
}

.active.item .icon-research {
	fill: #83A8C9 !important;
}

.tab-borderless.our-services .ui.secondary.pointing.menu {
	flex-wrap: wrap !important;
}

/* .ui.secondary.pointing.menu .item {
	width: 100%; 
	max-width: 33.33%; 
	min-width: 345px;
} */

.tab-borderless.our-services .ui.segment.active.tab {
    padding: 0 !important;
}

.tab-borderless.our-services .ui.segment.active.tab .ui.container {
    margin: 0 !important;
}

@media screen and (max-width: 500px) { /* mobile */
    h3.our-services {
        font-family: 'neue-haas-unica' !important;
        font-size: 30px !important;
        font-weight: 500 !important;
        line-height: 32px !important;
        letter-spacing: -0.04em;
        color: #0B2F4D !important;
    }
    .tab-borderless.our-services a {
		font-family: 'neue-haas-unica' !important;
		font-size: 30px !important;
		font-weight: 500 !important;
		line-height: 32px !important;
		letter-spacing: -0.04em;
		text-decoration-line: none !important;
	}
    .tab-borderless.our-services .ui.pointing.secondary.menu .item {
        width: 100%; 
        max-width: 500px;
    }
}

@media only screen and (max-width: 1357px) {
    .tab-borderless.our-services .ui.pointing.secondary.menu {
		max-width: 600px !important;
	}
    .tab-borderless.our-services .ui.pointing.secondary.menu .item:nth-child(1), .tab-borderless.our-services .ui.pointing.secondary.menu .item:nth-child(2) {
        margin-bottom: 40px !important;
    }
}

@media only screen and (min-width: 1358px) {
    .tab-borderless.our-services .ui.pointing.secondary.menu .item {
        margin-right: 40px !important;
    }
}