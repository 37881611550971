.tab-menu.ihope-genetic-health .ui.segment {
	border: 0 !important;
	box-shadow: none !important;
    font-size: unset !important;
}

.tab-menu.ihope-genetic-health .ui.secondary.pointing.menu {
	border-bottom: 0 !important;
}

.tab-menu.ihope-genetic-health .ui.secondary.pointing.menu .active.item {
	border-bottom: 4px solid #E4772A !important;
}

.ui.secondary.pointing.menu a {
	font-family: 'neue-haas-unica' !important;
	font-size: 36px !important;
	font-weight: 500 !important;
	line-height: 40px !important;
	letter-spacing: -0.05em;
	color: #8A9299 !important;
	text-decoration-line: none !important;
	padding-top: 0 !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
	padding-bottom: 15px !important;
}

.tab-menu.ihope-genetic-health .ui.secondary.pointing.menu .active.item span {
	color: #0B2F4D !important;
}

/* .tab-menu.ihope-genetic-health .ui.secondary.pointing.menu {
	flex-wrap: wrap !important;
} */

.tab-menu.ihope-genetic-health .ui.segment.active.tab {
    padding: 0 !important;
}

.tab-menu.ihope-genetic-health .ui.segment.active.tab .ui.container {
    margin: 0 !important;
}

@media screen and (max-width: 550px) { /* mobile font */
    h3.tab-menu.ihope-genetic-health {
        font-family: 'neue-haas-unica' !important;
        font-size: 30px !important;
        font-weight: 500 !important;
        line-height: 32px !important;
        letter-spacing: -0.04em;
        color: #0B2F4D !important;
    }
    .tab-menu.ihope-genetic-health .ui.secondary.pointing.menu a {
		font-family: 'neue-haas-unica' !important;
		font-size: 20px !important;
		font-weight: 500 !important;
		line-height: 32px !important;
		letter-spacing: -0.04em;
		text-decoration-line: none !important;
	}
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item {
        width: 100%; 
        max-width: 500px;
    }

    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu {
		width: 345px !important;
	}
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(2), 
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(3),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(4),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(5),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(6) {
        margin-bottom: 15px !important;
        width: fit-content !important;
    }
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(3) {
        width: fit-content !important;
    }
    .tab-menu.ihope-genetic-health .ui.secondary.pointing.menu {
        flex-wrap: wrap !important;
        flex-direction: row !important;
    }
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(2), 
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(3),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(4),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(5),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(6) {
        margin-right: 30px !important;
    }
}

@media only screen and (min-width: 551px) and (max-width: 1010px) { /* customize as needed */
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu {
		max-width: 590px !important;
	}
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(2), 
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(3),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(4),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(5),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(6) {
        margin-bottom: 30px !important;
        width: fit-content !important;
    }
    /* .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(3) {
        width: fit-content !important;
    } */
    .tab-menu.ihope-genetic-health .ui.secondary.pointing.menu {
        flex-wrap: wrap !important;
        flex-direction: row !important;
    }
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(2), 
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(3),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(4),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(5),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(6) {
        margin-right: 30px !important;
    }
}

@media only screen and (min-width: 1011px) {
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(2), 
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(3),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(4),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(5),
    .tab-menu.ihope-genetic-health .ui.pointing.secondary.menu .item:nth-child(6) {
        margin-right: 30px !important;
    }
}