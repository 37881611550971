table {
	border-collapse: collapse;
	width: 100%;
}

table td,
table th {
	border: 1px solid #ddd;
	padding: 5px;
}

table tr:nth-child(even) {
	background-color: #f2f2f2;
}

table tr:hover {
	background-color: #C3D7E9;
}

table th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: center;
	background-color: #145B90;
	color: white;
}
