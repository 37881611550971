#frontPageContent h1 {
	margin-top: 60px !important;
	margin-bottom: 60px !important;
}
#frontPageContent p {
	margin-bottom: 30px !important;
}
h3.link-underline-none {
	text-decoration-line: none !important;
}
h3.link-underline-none:hover {
	text-decoration-line: none !important;
}
h4.link-underline-none {
	text-decoration-line: none !important;
}
h4.home-tools-link.link-blue-800:hover {
	text-decoration-line: none !important;
}
#engagement {
	height: 280px;
}
#training {
	height: 280px;
}
#research {
	height: 280px;
}
@media screen and (min-width: 1200px) {
	.home-services-2 {
		display: none !important;
	}
}
@media screen and (max-width: 1199px) {
	.home-services-1 {
		display: none !important;
	}
	#engagement {
		margin-left: 40px;
	}
}
@media screen and (max-width: 392px) {
	.button-neutral-100 {
		padding: 7px 15px 11px !important;
	}
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
	.image-home {
		width: 100%;
	}
	.home-tools {
		margin-bottom: 40px !important;
	}
	#engagement {
		height: 155px;
	}
	#training {
		height: 155px;
	}
	#research {
		height: 155px;
	}
}
/* @media screen and (min-width: 1200px) and (max-width: 1400px) {
	.ui.container.container-main {
		width: auto !important;
		min-width: 1120px !important;
		margin-left: 40px !important; 
		margin-right: 40px !important;
	}
} */
@media screen and (min-width: 768px) and (max-width: 1400px) {
	.ui.container.container-main {
		width: auto !important;
		min-width: 714px !important;
		margin-left: 40px !important;
		margin-right: 40px !important;
	}
	.ui.container.subscribe-container {
		width: auto !important;
		min-width: 714px !important;
		margin-left: 40px !important;
		margin-right: 40px !important;
	}
}
@media screen and (max-width: 767px) {
	#engagement {
		height: 225px;
	}
	#training {
		height: 225px;
	}
	#research {
		height: 225px;
	}
}

.carousel {
	overflow: hidden;
	max-width: 1312px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.inner {
	white-space: nowrap;
	transition: transform 0.3s;
}

@media screen and (max-width: 768px) {
	.carousel-item {
		display: block !important;
	}
	.carousel-item:not(:last-child) {
		border-bottom: 1px solid #0b2f4d !important;
		margin-bottom: 50px !important;
	}
	.carousel-item-text {
		padding: 0px !important;
		margin: 20px 0px !important;
	}
}

.carousel-item {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.carousel-item-text {
	margin: 40px 0;
	padding: 0 25px;
	white-space: normal;
}
.carousel-img {
	width: 100%;
	max-width: 600px;
	height: auto;
	max-height: 400px;
	justify-content: center;
	display: flex;
}

.carousel-buttons {
	display: flex;
	justify-content: space-evenly;
}

.button-arrow {
	background: none;
	border: none;
	cursor: pointer;
	margin-top: 20px;
}

.indicators {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-top: 20px;
}

.indicator-buttons {
	border: none;
	cursor: pointer;
}

.indicators > button {
	margin: 5px;
	background: none;
}

.indicator-symbol {
	color: #0b2f4d;
}

.indicator-symbol-active {
	color: #e4772a;
}
