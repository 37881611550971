.tab-borderless.irb .ui.segment {
	border: 0 !important;
	box-shadow: none !important;
    font-size: unset !important;
}

.tab-borderless.irb .ui.secondary.pointing.menu {
	border-bottom: 0 !important;
}

.tab-borderless.irb .ui.secondary.pointing.menu .active.item {
	border-bottom: 4px solid #E4772A !important;
}

.ui.secondary.pointing.menu a {
	font-family: 'neue-haas-unica' !important;
	font-size: 36px !important;
	font-weight: 500 !important;
	line-height: 40px !important;
	letter-spacing: -0.05em;
	color: #8A9299 !important;
	text-decoration-line: none !important;
	padding-top: 0 !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
	padding-bottom: 15px !important;
}

.tab-borderless.irb .ui.secondary.pointing.menu .active.item span {
	color: #0B2F4D !important;
}

.tab-borderless.irb .ui.secondary.pointing.menu {
	flex-wrap: wrap !important;
}

.tab-borderless.irb .ui.segment.active.tab {
    padding: 0 !important;
}

.tab-borderless.irb .ui.segment.active.tab .ui.container {
    margin: 0 !important;
}

@media screen and (max-width: 500px) { /* mobile */
    h3.irb {
        font-family: 'neue-haas-unica' !important;
        font-size: 30px !important;
        font-weight: 500 !important;
        line-height: 32px !important;
        letter-spacing: -0.04em;
        color: #0B2F4D !important;
    }
    .ui.secondary.pointing.menu a {
		font-family: 'neue-haas-unica' !important;
		font-size: 30px !important;
		font-weight: 500 !important;
		line-height: 32px !important;
		letter-spacing: -0.04em;
		text-decoration-line: none !important;
	}
    .tab-borderless.irb .ui.pointing.secondary.menu .item {
        width: 100%; 
        max-width: 500px;
    }
}

@media only screen and (max-width: 815px) {
    .tab-borderless.irb .ui.pointing.secondary.menu {
		max-width: 340px !important;
	}
    .tab-borderless.irb .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-borderless.irb .ui.pointing.secondary.menu .item:nth-child(2) {
        margin-bottom: 40px !important;
        width: fit-content !important;
    }
    .tab-borderless.irb .ui.pointing.secondary.menu .item:nth-child(3) {
        width: fit-content !important;
    }
}

    .tab-borderless.irb .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-borderless.irb .ui.pointing.secondary.menu .item:nth-child(2)  {
        margin-right: 40px !important;
    }