.tab-menu.cross-condition-burden-of-disease-study .ui.segment {
	border: 0 !important;
	box-shadow: none !important;
    font-size: unset !important;
}

.tab-menu.cross-condition-burden-of-disease-study .ui.secondary.pointing.menu {
	border-bottom: 0 !important;
}

.tab-menu.cross-condition-burden-of-disease-study .ui.secondary.pointing.menu .active.item {
	border-bottom: 4px solid #E4772A !important;
}

.ui.secondary.pointing.menu a {
	font-family: 'neue-haas-unica' !important;
	font-size: 36px !important;
	font-weight: 500 !important;
	line-height: 40px !important;
	letter-spacing: -0.05em;
	color: #8A9299 !important;
	text-decoration-line: none !important;
	padding-top: 0 !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
	padding-bottom: 15px !important;
}

.tab-menu.cross-condition-burden-of-disease-study .ui.secondary.pointing.menu .active.item span {
	color: #0B2F4D !important;
}

/* .tab-menu.cross-condition-burden-of-disease-study .ui.secondary.pointing.menu {
	flex-wrap: wrap !important;
} */

.tab-menu.cross-condition-burden-of-disease-study .ui.segment.active.tab {
    padding: 0 !important;
}

.tab-menu.cross-condition-burden-of-disease-study .ui.segment.active.tab .ui.container {
    margin: 0 !important;
}

@media screen and (max-width: 450px) { /* mobile font */
    h3.tab-menu.cross-condition-burden-of-disease-study {
        font-family: 'neue-haas-unica' !important;
        font-size: 30px !important;
        font-weight: 500 !important;
        line-height: 32px !important;
        letter-spacing: -0.04em;
        color: #0B2F4D !important;
    }
    .tab-menu.cross-condition-burden-of-disease-study .ui.secondary.pointing.menu a {
		font-family: 'neue-haas-unica' !important;
		font-size: 20px !important;
		font-weight: 500 !important;
		line-height: 32px !important;
		letter-spacing: -0.04em;
		text-decoration-line: none !important;
	}
    .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu .item {
        width: fit-content; 
        max-width: 500px;
        margin-bottom: 15px; 
    }

    .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu {
		width: 345px !important;
	}

    /* adjust according to number of tabs */
    .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu .item:nth-child(2) {
        margin-bottom: 15px !important;
        width: fit-content !important;
    }
    /* .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu .item:nth-child(3) {
        width: fit-content !important;
    } */
    .tab-menu.cross-condition-burden-of-disease-study .ui.secondary.pointing.menu {
        flex-wrap: wrap !important;
        flex-direction: row !important;
    }
    /* adjust according to number of tabs */
    .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu .item:nth-child(2) {
        margin-right: 30px !important;
    }
}

/* customize as needed */
@media only screen and (min-width: 451px) and (max-width: 1010px) {
    .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu {
		max-width: 765px !important;
	}
    /* adjust according to number of tabs */
    .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu .item:nth-child(2) {
        margin-bottom: 30px !important;
        width: fit-content !important;
    }
    /* .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu .item:nth-child(3) {
        width: fit-content !important;
    } */
    .tab-menu.cross-condition-burden-of-disease-study .ui.secondary.pointing.menu {
        flex-wrap: wrap !important;
        flex-direction: row !important;
    }
    /* adjust according to number of tabs */
    .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu .item:nth-child(2) {
        margin-right: 30px !important;
    }
}

@media only screen and (min-width: 1011px) {
    /* adjust according to number of tabs */
    .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu .item:nth-child(1),
    .tab-menu.cross-condition-burden-of-disease-study .ui.pointing.secondary.menu .item:nth-child(2) {
        margin-right: 30px !important;
    }
}