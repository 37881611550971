#irbApplication .container-main {
	margin-bottom: 80px !important;
}

#irbApplication .ui.progress {
	height: 10px;
	width: 300px;
	border-radius: 5px;
	background-color: #dce2e5;
}

#irbApplication .ui.progress .bar {
	height: 10px;
	border-radius: 5px;
	background-color: #145b90;
}

#irbApplication .width-300 {
	width: 320px;
	/* 300 in Figma, 320 in actual */
}

#irbApplication div.ui.selection.dropdown {
	min-width: fit-content;
}

#irbApplication .ui.checkbox label {
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 16px !important;
	line-height: 20px !important;
	letter-spacing: -0.01em;
	color: #0b2f4d !important;
}

#irbApplication .label-input {
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 16px !important;
	line-height: 20px !important;
	letter-spacing: -0.01em;
	color: #0b2f4d !important;
}

#irbApplication .ui.checkbox label:before {
	top: 4px;
	border: 1px solid #a4acb2;
	width: 16px;
	height: 16px;
}

#irbApplication .ui.checkbox label:after {
	top: 3px;
	left: -1px;
	transform: scale(0.8);
	width: 18px;
	height: 18px;
	background-color: #145b90;
	border-radius: 3px;
	color: #fff !important;
}

#irbApplication .ui.form textarea {
	width: 100% !important;
	height: 120px !important;
}

/* Button as link within forms */
#irbApplication .button-neutral-100 {
	justify-content: unset !important;
	width: fit-content !important;
}

/* Prevent button text from changing color on hover within forms */
#irbApplication a.link-button:hover {
	color: unset !important;
}

#irbApplication .button-submit {
	float: left !important;
}

#irbApplication .p-small.error {
	font-family: 'neue-haas-unica' !important;
	font-weight: 300 !important;
	font-size: 16px !important;
	line-height: 20px !important;
	letter-spacing: -0.01em;
	color: #ff644c !important;
}

#irbApplication .text-copy-link {
	border: 1px solid gray;
	padding: 8px;
}

#irbApplication .close {
	position: absolute;
	right: 32px;
	top: 32px;
	width: 32px;
	height: 32px;
	opacity: 0.3;
}

#irbApplication .close:hover {
	opacity: 1;
}

#irbApplication .close:before,
#irbApplication .close:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 33px;
	width: 2px;
	background-color: #333;
}

#irbApplication .close:before {
	transform: rotate(45deg);
}

#irbApplication .close:after {
	transform: rotate(-45deg);
}

#popupSave .text {
	border-color: blue;
	border-width: 1px;
	border-radius: 1px;
}

#popupSave .email {
	border-color: blue;
	border-width: 1px;
	border-radius: 1px;
}