@media screen and (min-width: 747px) {
    #neighborhoodViewComputer {
        position: relative;
        width: 667px;
        height: 695px;
        margin: 0 auto;
    }
    #neighborhoodViewComputer li {
        list-style: none;
        position: absolute; 
        /* border: 1px solid blue; */
    }
    #netsNeighborhoodView .show-full-screen {
        display: none;
    }
    
    
    #neighborhoodViewComputer .menu-1 {
        top: 241px;
        left: 11px;
        width: 128px;
        height: 28px;
    }
    #neighborhoodViewComputer .menu-2 {
        top: 113px;
        left: 34px;
        width: 104px;
        height: 46px;
    }
    #neighborhoodViewComputer .menu-3 {
        top: 150px;
        left: 214px;
        width: 132px;
        height: 45px;
    }
    #neighborhoodViewComputer .menu-4 {
        top: 66px;
        left: 229px;
        width: 134px;
        height: 46px;
    }


    #neighborhoodViewComputer .menu-5 {
        top: 66px;
        left: 462px;
        width: 62px;
        height: 45px;
    }
    #neighborhoodViewComputer .menu-6 {
        top: 135px;
        left: 518px;
        width: 75px;
        height: 45px;
    }
    #neighborhoodViewComputer .menu-7 {
        top: 231px;
        left: 528px;
        width: 55px;
        height: 28px;
    }
    #neighborhoodViewComputer .menu-8 {
        top: 256px;
        left: 366px;
        width: 100px;
        height: 44px;
    }


    #neighborhoodViewComputer .menu-9 {
        top: 305px;
        left: 497px;
        width: 116px;
        height: 44px;
    }
    #neighborhoodViewComputer .menu-10 {
        top: 384px;
        left: 471px;
        width: 146px;
        height: 63px;
    }


    #neighborhoodViewComputer .menu-11 {
        top: 456px;
        left: 339px;
        width: 106px;
        height: 45px;
    }
    #neighborhoodViewComputer .menu-12 {
        top: 552px;
        left: 284px;
        width: 79px;
        height: 45px;
    }
    #neighborhoodViewComputer .menu-13 {
        top: 545px;
        left: 403px;
        width: 89px;
        height: 44px;
    }


    #neighborhoodViewComputer .menu-14 {
        top: 446px;
        left: 153px;
        width: 75px;
        height: 45px;
    }
    #neighborhoodViewComputer .menu-15 {
        top: 368px;
        left: 22px;
        width: 110px;
        height: 27px;
    }
    #neighborhoodViewComputer .menu-16 {
        top: 286px;
        left: 148px;
        width: 91px;
        height: 26px;
    }
    #neighborhoodViewComputer .menu-17 {
        top: 350px;
        left: 255px;
        width: 132px;
        height: 45px;
    }
    #neighborhoodViewComputer .menu-18 {
        top: 359px;
        left: 152px;
        width: 76px;
        height: 44px;
    }
    #neighborhoodViewComputer .menu-19 {
        top: 576px;
        left: 522px;
        width: 134px;
        height: 115px;
    }
}




@media screen and (min-width: 667px) and (max-width: 746px) {
    #neighborhoodViewTablet {
        position: relative;
        width: 587px;
        height: 612px;
        margin: 0 auto;
    }
    #neighborhoodViewTablet li {
        list-style: none;
        position: absolute; 
        /* border: 1px solid blue; */
    }
    #netsNeighborhoodView .show-full-screen {
        display: none;
    }
    
    
    
    #neighborhoodViewTablet .menu-1 {
        top: 212px;
        left: 9px;
        width: 114px;
        height: 25px;
    }
    #neighborhoodViewTablet .menu-2 {
        top: 100px;
        left: 29px;
        width: 93px;
        height: 39px;
    }
    #neighborhoodViewTablet .menu-3 {
        top: 131px;
        left: 188px;
        width: 117px;
        height: 40px;
    }
    #neighborhoodViewTablet .menu-4 {
        top: 58px;
        left: 201px;
        width: 119px;
        height: 40px;
    }




    #neighborhoodViewTablet .menu-5 {
        top: 58px;
        left: 406px;
        width: 55px;
        height: 40px;
    }
    #neighborhoodViewTablet .menu-6 {
        top: 118px;
        left: 456px;
        width: 66px;
        height: 41px;
    }
    #neighborhoodViewTablet .menu-7 {
        top: 203px;
        left: 464px;
        width: 49px;
        height: 25px;
    }
    #neighborhoodViewTablet .menu-7 {
        top: 203px;
        left: 464px;
        width: 49px;
        height: 25px;
    }
    #neighborhoodViewTablet .menu-8 {
        top: 225px;
        left: 322px;
        width: 88px;
        height: 40px;
    }



    #neighborhoodViewTablet .menu-9 {
        top: 268px;
        left: 437px;
        width: 103px;
        height: 40px;
    }
    #neighborhoodViewTablet .menu-10 {
        top: 337px;
        left: 414px;
        width: 130px;
        height: 57px;
    }



    #neighborhoodViewTablet .menu-11 {
        top: 401px;
        left: 298px;
        width: 94px;
        height: 41px;
    }
    #neighborhoodViewTablet .menu-12 {
        top: 486px;
        left: 250px;
        width: 70px;
        height: 40px;
    }
    #neighborhoodViewTablet .menu-13 {
        top: 479px;
        left: 354px;
        width: 80px;
        height: 41px;
    }



    #neighborhoodViewTablet .menu-14 {
        top: 392px;
        left: 134px;
        width: 67px;
        height: 41px;
    }
    #neighborhoodViewTablet .menu-15 {
        top: 323px;
        left: 18px;
        width: 99px;
        height: 25px;
    }
    #neighborhoodViewTablet .menu-16 {
        top: 250px;
        left: 130px;
        width: 81px;
        height: 25px;
    }
    #neighborhoodViewTablet .menu-17 {
        top: 308px;
        left: 224px;
        width: 117px;
        height: 40px;
    }
    #neighborhoodViewTablet .menu-18 {
        top: 315px;
        left: 133px;
        width: 68px;
        height: 41px;
    }
    #neighborhoodViewTablet .menu-19 {
        top: 506px;
        left: 459px;
        width: 120px;
        height: 103px;
    }
}


@media screen and (max-width: 666px) {
    #neighborhoodViewMobile {
        position: relative;
        width: 100%;
        max-width: 345px;
        height: auto;
        margin: 0 auto;
    }
    #netsNeighborhoodView .show-full-screen {
        display: unset;
        position: fixed;
        top: 0;
        left: 0;
        width:100%;
        height: 100%;
        z-index: 3;
    }
}



@media screen and (min-width: 747px) { /* computer */
    .computer-only.neighborhood-view-computer {
        display: unset !important; 
    }
    .tablet-only.neighborhood-view-tablet {
        display: none; 
    }
    .mobile-only.neighborhood-view-mobile {
        display: none; 
    }
}
@media screen and (min-width: 667px) and (max-width: 746px) { /* tablet */
    .computer-only.neighborhood-view-computer {
        display: none; 
    }
    .mobile-only.neighborhood-view-mobile {
        display: none; 
    }
}
@media screen and (max-width: 666px) { /* mobile */
    .tablet-only.neighborhood-view-tablet {
        display: none; 
    }
    .computer-only.neighborhood-view-computer {
        display: none; 
    }
    .mobile-only.neighborhood-view-mobile {
        display: unset !important;
    }
}




#fullScreenNeighborhoodView {
    position: relative;
    width: 667px;
    height: 695px;
    margin: 0 auto;
}
#fullScreenNeighborhoodView li {
    list-style: none;
    position: absolute; 
    /* border: 1px solid blue; */
}



#fullScreenNeighborhoodView .menu-1 {
    top: 241px;
    left: 11px;
    width: 128px;
    height: 28px;
}
#fullScreenNeighborhoodView .menu-2 {
    top: 113px;
    left: 34px;
    width: 104px;
    height: 46px;
}
#fullScreenNeighborhoodView .menu-3 {
    top: 150px;
    left: 214px;
    width: 132px;
    height: 45px;
}
#fullScreenNeighborhoodView .menu-4 {
    top: 66px;
    left: 229px;
    width: 134px;
    height: 46px;
}


#fullScreenNeighborhoodView .menu-5 {
    top: 66px;
    left: 462px;
    width: 62px;
    height: 45px;
}
#fullScreenNeighborhoodView .menu-6 {
    top: 135px;
    left: 518px;
    width: 75px;
    height: 45px;
}
#fullScreenNeighborhoodView .menu-7 {
    top: 231px;
    left: 528px;
    width: 55px;
    height: 28px;
}
#fullScreenNeighborhoodView .menu-8 {
    top: 256px;
    left: 366px;
    width: 100px;
    height: 44px;
}


#fullScreenNeighborhoodView .menu-9 {
    top: 305px;
    left: 497px;
    width: 116px;
    height: 44px;
}
#fullScreenNeighborhoodView .menu-10 {
    top: 384px;
    left: 471px;
    width: 146px;
    height: 63px;
}


#fullScreenNeighborhoodView .menu-11 {
    top: 456px;
    left: 339px;
    width: 106px;
    height: 45px;
}
#fullScreenNeighborhoodView .menu-12 {
    top: 552px;
    left: 284px;
    width: 79px;
    height: 45px;
}
#fullScreenNeighborhoodView .menu-13 {
    top: 545px;
    left: 403px;
    width: 89px;
    height: 44px;
}


#fullScreenNeighborhoodView .menu-14 {
    top: 446px;
    left: 153px;
    width: 75px;
    height: 45px;
}
#fullScreenNeighborhoodView .menu-15 {
    top: 368px;
    left: 22px;
    width: 110px;
    height: 27px;
}
#fullScreenNeighborhoodView .menu-16 {
    top: 286px;
    left: 148px;
    width: 91px;
    height: 26px;
}
#fullScreenNeighborhoodView .menu-17 {
    top: 350px;
    left: 255px;
    width: 132px;
    height: 45px;
}
#fullScreenNeighborhoodView .menu-18 {
    top: 359px;
    left: 152px;
    width: 76px;
    height: 44px;
}
#fullScreenNeighborhoodView .menu-19 {
    top: 576px;
    left: 522px;
    width: 134px;
    height: 115px;
}





.hide-full-screen {
    display: none; 
}
.full-screen-exit-container {
    position: fixed !important;
    width: 36px;
    height: 36px;
    top: 40px;
    right: 30px;
    z-index: 1;
    cursor: pointer;
    background: #fff;
    border-radius: 18px;
    box-shadow: 2px 4px 10px rgb(0 0 0 / 15%);
}
.full-screen-exit-image {
    position: fixed !important;
    top: 50px;
    right: 40px;
    z-index: 1;
    cursor: pointer;
}
