.navbar-border {
	border-bottom: 1px solid hsl(204, 16%, 94%);
}

.navbar-fixed {
	position: fixed;
    top: 0;
    z-index: 3;
    width: 100vw;
    background: #fff;
}

.navbar-container.ui.container {
	margin-bottom: 0px !important;
}

.navbar {
	/* background: #efefef; */
	/* border-bottom: 1px solid #edf0f2; */
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1.2rem;
	color: #294761;
	font-family: neue-haas-unica, sans-serif !important;
	font-weight: 700 !important;
	font-style: normal !important;
	src: url(https://use.typekit.net/dat2hxo.css);
	height: 80px;
}

.header-logo {
	width: 360px;
}

.header-logo-stacked {
	width: 130px;
    min-width: 130px;
}

.nav-down-icon {
	display: inline !important; 
	margin-left: 3px;
}

/* .nav-down-icon path {
	stroke: #0B2F4D;
}

.nav-down-icon path {
	stroke: #d07430;
} */

.multilevelMenu ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.multilevelMenu ul li {
	display: block;
	position: relative;
	float: left;
}

.multilevelMenu.showNav ul li {
	float: none;
}

.multilevelMenu li ul {
	display: none;
	z-index: 11;
}

.main-navigation li:last-child ul {
	width: 262px;
}

.multilevelMenu ul li a {
	display: block;
	padding: 15px;
	padding-top: 8px;
	padding-bottom: 8px;
	text-decoration: none !important;
	white-space: nowrap;
	color: #0B2F4D;
}

.multilevelMenu ul li a:hover {
	/* background: #294761; */
	color: #145B90 !important;
}

.multilevelMenu.showNav ul li a:hover {
	/* background: #294761; */
	color: #83A8C9 !important;
}

.nav-item li a {
	padding: 0.4em 1em !important; 
}

.nav-item li:nth-child(1) {
	padding-top: .4em !important; 
}

.nav-item li:last-child {
	padding-bottom: .4em !important; 
	background-color: #fff;
}

.multilevelMenu li:hover > ul {
	display: block;
	position: absolute;
	border: 1px solid #EDF0F2;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
	border-radius: 4px;
	left: 10%;
	top: 95%;
}

.multilevelMenu li:hover li {
	float: none;
}
.multilevelMenu ul ul ul {
	left: 100%;
	top: 0;
}
.multilevelMenu ul:before,
.multilevelMenu ul:after {
	content: ' '; /* 1 */
	display: table; /* 2 */
}

.multilevelMenu ul:after {
	clear: both;
}

.multilevelMenu li:hover a {
	background: #fff;
}

.multilevelMenu.showNav li:hover a {
	background: none;
}

.multilevelMenu li:hover li a:hover {
	background: #fff;
	color: #145B90;
}

.multilevelMenu .main-navigation li ul li {
	border-top: 0;
}

.fa-bars {
	color: #294761;
}

.nav-links {
	stroke: #0B2F4D;
}

.nav-links.active {
	color: #145B90 !important;
	stroke: #0B2F4D!important;
}

.nav-links:hover {
	stroke: #0B2F4D!important;
}

.nav-item .mobile {
	color: #fff !important;
}

.nested-links.active {
	color: #145B90 !important;
}

.nested-links.mobile.active {
	color: #83A8C9 !important;
}

.nested-links.active:hover {
	/* background-color: #fff !important; */
	color: #145B90 !important;
}

.nav-links-mobile {
	display: none;
}

.nav-links.nested-links.sans-menu.mobile {
	color: #fff !important;
}

.sans-menu.mobile {
	color: #fff !important;
}

.menu-icon {
	display: none;
}

.menu-exit {
	width: 11px; 
	top: -50px;
}

.white {
	color: #fff !important;
}

.title.p-compact-bold.white {
	padding-left: 15px !important; 
	padding-right: 15px !important; 
	padding-bottom: 0 !important;
	padding-top: 0 !important;
}

.nav-links.nested-links.p-compact-bold.white.mobile {
	padding-top: 0;
	padding-bottom: 0;
}

.inline-flex {
	display: inline-flex; 
}

.search-icon {
	width: 16px; 
	height: 16px;
	align-self: center;
	margin-left: 15px;
}

.display-none {
	display: none !important; 
}

.donate-home {
	font-size: 18px !important;
}

@media screen and (min-width: 1016px) {
	.showNav {
		display: none;
	}
}

@media screen and (max-width: 1015px) {
	.hideNav {
		display: none;
	}

	.NavbarItems {
		position: relative;
	}

	.nav-menu {
		display: flex;
		justify-content: left;
		flex-direction: column;
		width: 50%;
		/* height: 80vh; */
		position: absolute;
		top: 0; /* temp fix */
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
		padding-top: 90px !important; 
		padding-bottom: 90px !important;
	}

	.nav-menu a {
		padding: 2%;
		text-decoration: none;
		/* font-size: 25px; */
		color: #294761;
		display: block;
		transition: 0.3s;
		text-align: left;
	}

	.nav-menu.active {
		background: #0B2F4D;
		left: 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 11;
		width: 100%;
	}

	.nav-links {
		text-align: center;
		padding: 2rem;
		width: 100%;
		display: table;
	}

	.menu-icon {
		display: block;
		position: absolute;
		/* top: 65px; */
		right: 15px;
		font-size: 1.8rem;
		cursor: pointer;
	}

	.fa-times {
		color: #132d40;
		font-size: 2rem;
	}

	.nav-links-mobile {
		display: block;
		text-align: center;
		padding: 1.5rem;
		margin: 2rem auto;
		border-radius: 4px;
		width: 80%;
		background: #132d40;
		text-decoration: none;
		color: #fff;
		font-size: 1.5rem;
	}

	.nav-links-mobile:hover {
		background: #fff;
		color: #132d40;
		transition: 250ms;
	}

	button {
		display: none;
	}

	button.social-share-button {
		display: inline-block !important;
	}
}

@media only screen and (max-width: 991px) {
	.header-logo {
		width: 280px;
	}
}

@media only screen and (max-width: 767px) {
	.navbar-padding-right {
		padding-right: 20px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 793px) {
	.navbar-padding-right {
		padding-right: 25px;
	}
}

@media only screen and (max-width: 457px) {
	.header-logo-full {
		display: none !important;
	}
}

@media only screen and (min-width: 458px) {
	.header-logo-stacked {
		display: none !important;
	}
}