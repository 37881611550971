body {
	font-size: 19px !important;
	font-family: 'neue-haas-unica' !important;
	font-style: normal !important;
	font-weight: 300 !important;
	line-height: 28px !important;
	color: #0b2f4d !important;
}

.icon-social-container {
	position: relative;
	top: 0;
	left: 0;
	display: inline-block;
	border: 1px #000 solid;
	border-radius: 50%;
}
.icon-social-container:hover {
	cursor: pointer;
	background-color: #005493;
	border-color: #005493;
	fill: white;
}
.icon-social-container:active {
	cursor: pointer;
	background-color: unset;
	border-color: unset;
	fill: unset;
	outline: 1px #b6d0e8 solid;
}
.icon-social-background {
	position: relative;
	top: 0;
	left: 0;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}
.icon-social-svg {
	position: absolute !important;
	top: 12px;
	left: 12px;
}
.icon-social-container.share-email {
	position: relative;
	top: 0;
	left: 0;
	display: inline-block;
	border: 1px #000 solid;
	border-radius: 50%;
	stroke: black;
}
.icon-social-container.share-email:hover {
	cursor: pointer;
	background-color: #005493;
	border-color: #005493;
	stroke: white;
}
.icon-social-container.share-email:active {
	cursor: pointer;
	background-color: unset;
	border-color: unset;
	stroke: black;
	outline: 1px #b6d0e8 solid;
}
.icon-social-background.share-email {
	position: relative;
	top: 0;
	left: 0;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}
.icon-social-svg.share-email {
	position: absolute !important;
	top: 12px;
	left: 12px;
}

/* .share-email-svg {
	fill: none !important;
} */
/* .share-email {
	stroke: white;
} */

.text-small {
	font-size: 14px !important;
}

.text-center {
	text-align: center !important;
}

.text-right {
	text-align: right;
}

.mt-8 {
	margin-top: 8px;
}

.mt-15 {
	margin-top: 15px !important;
}

.mt-5 {
	margin-top: 5px !important;
}

.mt-80 {
	margin-top: 80px !important;
}

.mb-80 {
	margin-bottom: 80px !important;
}

.mb-60 {
	margin-bottom: 60px !important;
}

.mt-0 {
	margin-top: 0px !important;
}

.mb-0 {
	margin-bottom: 0px !important;
}

.mb-8 {
	margin-bottom: 8px !important;
}

.mt-8 {
	margin-top: 8px;
}

.ml-5 {
	margin-left: 5px;
}

.ml-15 {
	margin-left: 15px !important;
}

.pb-0 {
	padding-bottom: 0px !important;
}

.pb-15 {
	padding-bottom: 15px !important;
}

.pb-20 {
	padding-bottom: 20px !important;
}

.ml-25 {
	margin-left: 25px !important;
}

.mr-80 {
	margin-right: 80px !important;
}

.mr-25 {
	margin-right: 25px !important;
}

.mr-8 {
	margin-right: 8px;
}

.mt-40 {
	margin-top: 40px !important;
}
.mb-40 {
	margin-bottom: 40px !important;
}
.mt-50 {
	margin-top: 50px;
}

.mb-50 {
	margin-bottom: 50px !important;
}

.mt-70 {
	margin-top: 70px !important;
}

.mt-100 {
	margin-top: 100px !important;
}

.mb-100 {
	margin-bottom: 100px;
}

.mt-150 {
	margin-top: 150px !important;
}

.mt-150 {
	margin-top: 150px;
}

.mt-15 {
	margin-top: 15px !important;
}

.mb-5 {
	margin-bottom: 5px !important;
}

.mb-15 {
	margin-bottom: 15px !important;
}

.mr-15 {
	margin-right: 15px !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mt-25 {
	margin-top: 25px !important;
}

.mb-25 {
	margin-bottom: 25px !important;
}

.mr-25 {
	margin-right: 25px !important;
}

.mt-30 {
	margin-top: 30px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.ml-30 {
	margin-left: 30px !important;
}

.mr-30 {
	margin-right: 30px !important;
}

.mt-120 {
	margin-top: 120px;
}

.mb-120 {
	margin-bottom: 120px;
}

.mr-16 {
	margin-right: 16px;
}

.mt-60 {
	margin-top: 60px;
}

.bold {
	font-weight: bold;
}
h1,
h2 {
	font-family: 'ivypresto-headline' !important;
	font-size: 60px !important;
	line-height: 60px !important;
	font-weight: 300 !important;
	color: #0b2f4d !important;
}
h3 {
	font-family: 'neue-haas-unica' !important;
	font-size: 36px !important;
	font-weight: 500 !important;
	line-height: 40px !important;
	letter-spacing: -0.05em;
	color: #0b2f4d !important;
}
h4 {
	font-family: 'neue-haas-unica' !important;
	font-weight: 500 !important;
	font-size: 24px !important;
	line-height: 32px !important;
	letter-spacing: -0.01em;
	color: #0b2f4d !important;
}
.h1-super {
	font-family: 'ivypresto-headline' !important;
	font-size: 100px !important;
	line-height: 100px !important;
	font-weight: 400 !important;
	letter-spacing: -0.015em;
	color: #0b2f4d !important;
}
.subtitle {
	font-family: 'neue-haas-unica' !important;
	font-weight: 300 !important;
	font-size: 24px !important;
	line-height: 32px !important;
	letter-spacing: -0.01em;
	color: #0b2f4d !important;
}
.sans-menu {
	font-family: 'neue-haas-unica' !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 24px !important;
	color: #0b2f4d !important;
}
p {
	font-family: 'neue-haas-unica' !important;
	font-weight: 300 !important;
	font-size: 19px !important;
	line-height: 28px !important;
	color: #0b2f4d !important;
}
b {
	font-weight: 500 !important;
}
.p-compact {
	font-family: 'neue-haas-unica' !important;
	font-weight: 300 !important;
	font-size: 19px !important;
	line-height: 24px !important;
	color: #0b2f4d !important;
}
.p-compact-bold {
	font-family: 'neue-haas-unica' !important;
	font-weight: 400 !important;
	font-size: 19px !important;
	line-height: 24px !important;
	color: #0b2f4d !important;
}
.p-small {
	font-family: 'neue-haas-unica' !important;
	font-weight: 300 !important;
	font-size: 16px !important;
	line-height: 20px !important;
	letter-spacing: -0.01em;
	color: #0b2f4d !important;
}
.button-text {
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 18px !important;
	line-height: 20px !important;
}
.inner-border-form {
	border: 1px solid #0b2f4d !important;
	border-radius: 2px !important;
}

form input,
form div.ui.selection.dropdown {
	border: 1px solid #0b2f4d !important;
	border-radius: 2px !important;
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 16px !important;
	line-height: 20px !important;
	letter-spacing: -0.01em;
	color: #0b2f4d !important;
	height: 55px;
}
form textarea {
	border: 1px solid #0b2f4d !important;
	border-radius: 2px !important;
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 16px !important;
	line-height: 20px !important;
	letter-spacing: -0.01em;
	color: #0b2f4d !important;
	height: 120px;
}
form div.ui.selection.dropdown {
	display: flex;
}
form input::placeholder,
form textarea::placeholder,
form div.divider.default.text {
	color: #8a9299 !important;
	align-self: center;
}
form div.divider.text {
	align-self: center;
}
form i.dropdown.icon {
	height: 100% !important;
	transform: scale3d(1.5, 2, 1.5);
	color: #a4acb2;
	top: 40% !important;
}
form div.ui.radio.checkbox label {
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 16px !important;
	line-height: 20px !important;
	letter-spacing: -0.01em;
	color: #0b2f4d !important;
}
form .ui.checkbox label:before {
	border: 1px solid #a4acb2;
}
form .ui.radio.checkbox label:before {
	width: 16px !important;
	height: 16px !important;
	top: 4px !important;
}
form .ui.radio.checkbox label:after {
	width: 16px !important;
	height: 16px !important;
	top: 4px !important;
	left: 0px !important;
	border-radius: 50% !important;
}
form .ui.radio.checkbox input:checked ~ label:after {
	background-color: unset !important;
	border: 5px solid #145b90 !important;
}
form .ui.radio.checkbox input:focus:checked ~ label:after {
	background-color: unset !important;
}
form .ui.radio.checkbox label:after {
	transform: none !important;
	background-color: unset !important;
}
form li {
	font-size: 19px !important;
	font-family: 'neue-haas-unica' !important;
	font-style: normal !important;
	font-weight: 350 !important;
	line-height: 28px !important;
	color: #0b2f4d !important;
}

.ui.checkbox label {
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 16px !important;
	line-height: 20px !important;
	letter-spacing: -0.01em;
	color: #0b2f4d !important;
}
.ui.checkbox label:before {
	top: 4px !important;
	border: 1px solid #a4acb2 !important;
	width: 16px !important;
	height: 16px !important;
}
.ui.checkbox label:after {
	top: 3px !important;
	left: -1px !important;
	transform: scale(0.8) !important;
	width: 18px !important;
	height: 18px !important;
	background-color: #145b90 !important;
	border-radius: 3px !important;
	color: #fff !important;
}
a {
	font-family: 'neue-haas-unica' !important;
	font-weight: 300 !important;
	font-size: 19px !important;
	line-height: 28px !important;
	text-decoration-line: underline !important;
	color: #0b2f4d !important;
}
a:hover {
	color: #145b90 !important;
}
a.link-publications {
	text-decoration-line: none !important;
}
a.link-button {
	text-decoration-line: none !important;
}
.link-underline-none {
	text-decoration-line: none !important;
}
.orange-500 {
	color: #e4772a !important;
}

.link-blue-800 {
	color: #0b2f4d !important;
	text-decoration: underline !important;
}

.link-blue-800:hover {
	color: #145b90 !important;
	text-decoration: underline !important;
}

.neutral-button {
	background: #edf0f2 !important;
	border-radius: 28px !important;
	padding: 16px 24px 16px !important;
}
.neutral-button:hover {
	background: #dce2e6 !important;
}
.blue-button {
	background: #0b2f4d !important;
	border-radius: 28px !important;
	padding: 16px 24px 16px !important;
	color: white !important;
}
.blue-button:hover {
	background: #005493 !important;
}
.orange-button {
	background: #e4772a !important;
	border-radius: 28px !important;
	padding: 16px 24px 16px !important;
	color: white !important;
}
.orange-button:hover {
	background: #af5d1c !important;
}
.field-title {
	font-family: 'neue-haas-unica' !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 21px !important;
	letter-spacing: 0.15em !important;
	text-transform: uppercase !important;
	color: #0b2f4d !important;
}
/* span {
	font-family: ivypresto-text, serif !important;
	font-weight: 400 !important;
	font-style: normal !important;
	src: url(https://use.typekit.net/dat2hxo.css);
	font-size: 18px !important;
}
form p {
	font-family: neue-haas-unica, sans-serif !important;
	font-weight: 400 !important;
	font-style: normal !important;
	src: url(https://use.typekit.net/dat2hxo.css);
}
.button {
	font-family: neue-haas-unica, sans-serif !important;
	font-weight: 400 !important;
	font-style: normal !important;
	src: url(https://use.typekit.net/dat2hxo.css);
}
label {
	font-family: neue-haas-unica, sans-serif !important;
	font-weight: 400 !important;
	font-style: normal !important;
	src: url(https://use.typekit.net/dat2hxo.css);
} */

.color-primary {
	color: #0b2f4d !important;
}

.color-secondary {
	color: #ff644c !important;
}

.list-right li:before {
	content: '\f105';
}

ul[class*='list'] {
	list-style-type: none;
}

.list-space li {
	margin-top: 10px;
}

[class*='list'] li:before {
	color: #d07430;
	font-family: FontAwesome;
	float: left;
	margin-left: -1.3em;
}

p:last-child {
	margin-bottom: 0 0 1em !important;
}

.pl-0 {
	padding-left: 0px !important;
}

.pl-15 {
	padding-left: 15px !important;
}

.pt-15 {
	padding-top: 15px !important;
}

.pt-25 {
	padding-top: 25px !important;
}

.pb-25 {
	padding-bottom: 25px !important;
}

.pb-40 {
	padding-bottom: 40px !important;
}

.pr-0 {
	padding-right: 0px !important;
}

.space-evenly {
	justify-content: space-evenly;
}

.justify-center {
	justify-content: center;
}

.border-solid {
	border: solid 1px;
}

.staff-icon-size {
	object-fit: cover;
	width: 304px;
	height: auto;
	aspect-ratio: 1/1;
	border-radius: 8px;
}

.round-gray-button {
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	padding: 7px 24px 11px !important;

	position: static !important;
	width: 300px;
	height: 46px !important;
	left: 0px !important;
	top: 144px !important;

	background-color: #edf0f2 !important;
	border-radius: 28px !important;

	flex: none !important;
	order: 2 !important;
	flex-grow: 0 !important;
	margin: 24px 0px !important;

	font-size: 19px !important;
	color: #0b2f4d !important;
}

/* .long-orange-button {
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	padding: 7px 24px 11px !important;

	position: static !important;
	max-width: 640px !important;
	height: 46px !important;
	left: 400px !important;
	top: 2745px !important;

	background: #E4772A !important;
	border-radius: 28px !important;

	font-size: 19px !important; 
	line-height: 28px !important;
	color: white !important;
} */
.button-orange-500 {
	background-color: #e4772a !important;
	color: #fff !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	text-align: left !important;
	box-sizing: content-box !important;
	padding: 14px 24px !important;
	gap: 8px !important;
	border-radius: 28px !important;
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 18px !important;
	line-height: 20px !important;
}

.button-orange-500:hover {
	background-color: #af5d1c !important;
}

.button-neutral-0 {
	padding: 14px 24px !important;
	gap: 8px !important;
	height: 46px !important;
	background-color: unset !important;
	color: #fff !important;
	border: 1px solid #fff !important;
	border-radius: 28px !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 18px !important;
	line-height: 20px !important;
}

.button-neutral-0:hover {
	border: transparent !important;
}

.button-neutral-orange-500 {
	padding: 14px 24px !important;
	gap: 8px !important;
	height: 46px !important;
	background-color: unset !important;
	color: #e4772a !important;
	border: 1px solid #e4772a !important;
	border-radius: 28px !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 18px !important;
	line-height: 20px !important;
}

.button-neutral-orange-500:hover {
	/* border: transparent !important; */
	background-color: #DCE2E5 !important;
}

.button-neutral-100 {
	padding: 7px 24px 11px !important;
	height: 46px !important;
	background-color: #edf0f2 !important;
	color: #0b2f4d !important;
	border-radius: 28px !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 19px !important;
	line-height: 28px !important;
	gap: 8px !important;
}

.button-neutral-100:hover {
	background-color: #dce2e5 !important;
}

.button-neutral-200 {
	padding: 7px 24px 11px !important;
	height: 46px !important;
	background-color: #DCE2E5 !important;
	color: #0b2f4d !important;
	border-radius: 28px !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 19px !important;
	line-height: 28px !important;
	gap: 8px !important;
}

.button-neutral-200:hover {
	background-color: #C0C7CC !important;
}

.button-blue-500 {
	padding: 7px 24px 11px !important;
	height: 46px !important;
	background-color: #145B90 !important;
	color: #ffffff !important;
	border-radius: 28px !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 19px !important;
	line-height: 28px !important;
	gap: 8px;
}

.button-blue-800 {
	padding: 7px 24px 11px !important;
	height: 46px !important;
	background-color: #0b2f4d !important;
	color: #ffffff !important;
	border-radius: 28px !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 19px !important;
	line-height: 28px !important;
	gap: 8px;
}

.button-blue-800:hover {
	background-color: #145b90 !important;
}

.button-white {
	background-color: unset !important;
	color: #0b2f4d !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	text-align: left !important;
	box-sizing: content-box !important;
	padding: 14px 24px !important;
	gap: 8px !important;
	/* height: 48px !important; */
	border: 1px solid #0b2f4d !important;
	border-radius: 28px !important;
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 18px !important;
	line-height: 20px !important;
}

.button-white:hover {
	background-color: #edf0f2 !important;
}

.button-negative-red {
	padding: 7px 24px 11px !important;
	height: 46px !important;
	background-color: red !important;
	color: #fff !important;
	border-radius: 28px !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 19px !important;
	line-height: 28px !important;
	gap: 8px !important;
}

.button-blue-400 {
	padding: 7px 24px 11px !important;
	height: 46px !important;
	background-color: #83A8C9 !important;
	color: #fff !important;
	border-radius: 28px !important;
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	font-family: 'neue-haas-unica' !important;
	font-weight: 350 !important;
	font-size: 19px !important;
	line-height: 28px !important;
	gap: 8px !important;
}

.button-full-width {
	width: 100%;
}

.button-quarter-width {
	width: 25%;
}

.center {
	margin: auto !important;
}

.rule-orange-500 {
	border: 1px solid #e4772a !important;
	border-bottom: 0 !important;
}

.rule-blue-200 {
	border: 1px solid #e1edf7;
	border-bottom: 0;
}

.rule-blue-300 {
	border: 1px solid #c3d7e9;
	border-bottom: 0;
}

.rule-blue-800 {
	border: 1px solid #0b2f4d;
	border-bottom: 0;
}

.rule-blue-400 {
	border: 1px solid #83a8c9;
	border-bottom: 0;
}

.rule-blue-500 {
	border: 1px solid #145b90;
	border-bottom: 0;
}

.rule-navy {
	border: 1px solid #546d83;
	border-bottom: 0;
}

.mw-700 {
	max-width: 700px;
}

.mw-600 {
	max-width: 600px;
}

.header-tile {
	font-family: 'neue-haas-unica' !important;
	font-size: 36px !important;
	font-weight: 400 !important;
	line-height: 48px !important;
	letter-spacing: -0.03em;
	color: #0b2f4d !important;
}

.inline {
	display: inline !important;
}

.inline-block {
	display: inline-block !important;
}

.display-contents {
	display: contents;
}

.display-block {
	display: block !important;
}

.footer-logo-text {
	max-width: 500px;
}

@media screen and (max-width: 768px) {
	.img-center-mobile {
		margin: 0 auto;
	}
	.mb-hr-50 {
		margin-bottom: 50px;
	}
	.mt-hr-0 {
		margin-top: 0px;
	}
	.text-right-mobile {
		text-align: right;
	}
}

.error {
	font-family: 'neue-haas-unica' !important;
	font-size: 19px !important;
	font-weight: 350 !important;
	line-height: 24px !important;
	letter-spacing: 0em !important;
	color: #ff644c !important;
}

.error-color {
	color: #ff644c !important;
}

.missing-field {
	margin-top: 40px !important;
}

.error-border {
	border: 1px solid #ff644c !important;
}

.ui.container {
	margin-bottom: 50px !important;
}

.content-container .ui.container {
	margin: auto 15px !important;
}

.title-dropdown {
	display: inline;
}

.icon-dropdown {
	float: right !important;
}

.icon-dropdown.active {
	transform: rotate(180deg) !important;
}

.title-accordion {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.image-radius {
	border-radius: 8px;
}

.sub-container-odyssey {
	max-width: 1312px;
	margin: auto;
}

#paddingOdysseyColumn1 {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
#paddingOdysseyColumn2 {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.scrim-white-background {
	display: block;
	background: #fff;
}

a.scrim-white-background:hover h4 {
	color: #145b90 !important;
}

a.scrim-white-background:hover p {
	color: #145b90 !important;
}

a.scrim-white-background:hover img {
	opacity: 0.75;
}

.scrim-white-background:hover img {
	opacity: 0.75 !important;
}

.scrim-white-background:hover a {
	color: #145b90 !important;
}

.scrim-white-background:hover p {
	color: #145b90 !important;
}

.scrim-white-background:hover {
	cursor: pointer !important;
}

.link-internal-icon {
	width: 21px;
	float: right;
}

.link-modal {
	color: #fff !important;
}

.link-modal:hover {
	color: #b6d0e8 !important;
}

.blue-400 {
	color: #83a8c9 !important;
}

.white-text {
	color: #fff !important;
}

.icon-width-16px {
	width: 16px;
}

.display-flex {
	display: flex !important;
}

.wrap {
	flex-wrap: wrap !important;
}

.display-contents {
	display: contents;
}

.space-between {
	justify-content: space-between;
}

.space-around {
	justify-content: space-around !important;
}

.flex-end {
	justify-content: flex-end;
}

.align-center {
	align-items: center !important;
}

#newBlogImageUpload {
	display: inline-block !important;
}

#newBlogGridRowGap {
	justify-content: space-between !important;
}

.width-560px {
	max-width: 560px !important;
}

.portrait-64px {
	width: 64px !important;
	height: 64px !important;
}

.gap-20px {
	gap: 20px !important;
}

.flex-direction-column {
	flex-direction: column !important;
}

.cursor-pointer {
	cursor: pointer !important;
}

#iHopeGeneticHealthNewsLetters {
	margin-top: 0px !important
}

.align-items-end {
	align-items: end !important;
}

.max-width-864px {
	max-width: 864px !important;
}


/* container for colored background sections */
.ui.container.container-colored-section {
	margin-top: 0px !important;
	margin-bottom: 0px !important;
}

/* for paragraphs that are max-width 560px in computer only */
@media screen and (min-width: 992px) {
	.computer-width-560px {
		max-width: 560px !important;
	}
	.computer-align-items-end {
		align-items: end !important;
	}
	.computer-justify-center {
		justify-content: center !important;
	}
	.computer-display-flex {
		display: flex !important;
	}
}

@media screen and (max-width: 767px) {
	.modal-video-mb-30 {
		margin-bottom: 30px !important;
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.modal-video-mb-60 {
		margin-bottom: 60px !important;
	}
}

@media screen and (min-width: 1200px) {
	.ui.container {
		width: 1312px !important;
		margin-left: auto !important;
		margin-right: auto !important;
	}
	.pt-32-0-0 {
		padding-top: 32px !important;
	}
	.home-services-2 {
		display: none !important;
	}
}

@media screen and (max-width: 1199px) {
	.h1-super {
		font-family: 'ivypresto-headline' !important;
		font-size: 60px !important;
		line-height: 60px !important;
		font-weight: 400 !important;
		letter-spacing: -0.01em;
		color: #0b2f4d !important;
	}
	h1.h1-mobile {
		font-family: 'ivypresto-headline' !important;
		font-size: 48px !important;
		line-height: 48px !important;
		font-weight: 300 !important;
		color: #0b2f4d !important;
	}
	.home-services-1 {
		display: none !important;
	}
}

@media screen and (max-width: 500px) {
	/* mobile */
	.computer-only {
		display: none !important;
	}
	.tablet-only {
		display: none !important;
	}
	.computer-tablet-only {
		display: none !important;
	}
	.mb-80-40-20 {
		margin-bottom: 20px !important;
	}
	.grid-row.mb-80-40-20 {
		margin-bottom: 0px !important;
	}
	.mt-80-60-40 {
		margin-top: 40px !important;
	}
	.mb-0-60-40 {
		margin-bottom: 40px !important;
	}
	.mt-120-60-40 {
		margin-top: 40px !important;
	}
	.mb-120-60-40 {
		margin-bottom: 40px !important;
	}
	.mb-20-20-40 {
		margin-bottom: 40px !important;
	}
	.mb-80-35-15 {
		margin-bottom: 15px !important;
	}
	.mb-50-30-10 {
		margin-bottom: 10px !important;
	}
	.mt-65-45-25 {
		margin-top: 25px !important;
	}
	.mb-75-55-15 {
		margin-bottom: 15px !important;
	}
	.mt-75-55-35 {
		margin-top: 35px !important;
	}
	h1 {
		font-family: 'ivypresto-headline' !important;
		font-size: 48px !important;
		line-height: 48px !important;
		font-weight: 300 !important;
		color: #0b2f4d !important;
	}
	.mb-80-50-40 {
		margin-bottom: 40px !important;
	}
	.mb-80-60-40 {
		margin-bottom: 40px !important;
	}
	.mb-80-70-40 {
		margin-bottom: 40px !important;
	}
	.mt-80-70-40 {
		margin-top: 40px !important;
	}
	.ui.container.container-mobile {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.hide-mobile {
		display: none !important;
	}
	.ui.container.container-main {
		margin-top: 120px !important; /* to re-visit after updating header */
	}
	.mb-70-60-30 {
		margin-bottom: 30px !important;
	}
	.mb-65-55-25 {
		margin-bottom: 25px !important;
	}
	.p-compact-mobile {
		font-family: 'neue-haas-unica' !important;
		font-weight: 300 !important;
		font-size: 19px !important;
		line-height: 24px !important;
		color: #0b2f4d !important;
	}
	.image-radius {
		width: 100% !important;
		height: auto;
	}
	.p-small {
		font-family: 'neue-haas-unica' !important;
		font-weight: 300 !important;
		font-size: 16px !important;
		line-height: 20px !important;
		letter-spacing: -0.01em;
		color: #0b2f4d !important;
	}
	.mb-80-60-60 {
		margin-bottom: 60px !important;
	}
	.mb-80-40-40 {
		margin-bottom: 40px !important;
	}
	.pb-80-40-40 {
		padding-bottom: 40px !important;
	}
	.pt-80-40-40 {
		padding-top: 40px !important;
	}
	.mt-30-0-0 {
		margin-top: 0px !important;
	}
	.mt-40-0-0 {
		margin-top: 0px !important;
	}
	.pb-80-40-10 {
		padding-bottom: 10px !important;
	}
	.mb-70-10-0 {
		margin-bottom: 0px !important;
	}
	.pb-30-1-1 {
		padding-bottom: 1px !important;
	}
	.mb-90-60-40 {
		margin-bottom: 40px !important;
	}
	.mt-160-160-120 {
		margin-top: 120px !important;
	}
	.mt-80-80-40 {
		margin-top: 40px !important;
	}
	.mb-40-60-40 {
		margin-bottom: 40px !important;
	}
	.mb-60-20-0 {
		margin-bottom: 0px !important;
	}
	.mt-40-40-15 {
		margin-top: 15px !important;
	}
	.mb-120-60-40 {
		margin-bottom: 40px !important;
	}
	.pt-80-60-40 {
		padding-top: 40px !important;
	}
	.pb-80-60-40 {
		padding-bottom: 40px !important;
	}
}

@media screen and (min-width: 501px) and (max-width: 991px) {
	/* tablet */
	.computer-only {
		display: none !important;
	}
	.mobile-only {
		display: none !important;
	}
	.mb-80-40-20 {
		margin-bottom: 40px !important;
	}
	.mb-0-20-0 {
		margin-bottom: 20px !important;
	}
	.mt-80-60-40 {
		margin-top: 60px !important;
	}
	.mb-0-60-40 {
		margin-bottom: 60px !important;
	}
	.mt-120-60-40 {
		margin-top: 60px !important;
	}
	.mb-120-60-40 {
		margin-bottom: 60px !important;
	}
	.mb-120-60-40 {
		margin-bottom: 60px !important;
	}
	.mb-20-20-40 {
		margin-bottom: 20px !important;
	}
	.mb-80-35-15 {
		margin-bottom: 35px !important;
	}
	.mb-50-30-10 {
		margin-bottom: 30px !important;
	}
	.mt-65-45-25 {
		margin-top: 45px !important;
	}
	.mb-75-55-15 {
		margin-bottom: 55px !important;
	}
	.mt-75-55-35 {
		margin-top: 55px !important;
	}
	.mb-80-50-40 {
		margin-bottom: 50px !important;
	}
	.mb-80-60-40 {
		margin-bottom: 60px !important;
	}
	.mb-80-70-40 {
		margin-bottom: 70px !important;
	}
	.mt-80-70-40 {
		margin-top: 70px !important;
	}
	.ui.container.container-mobile {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.ui.container.container-main {
		margin-top: 160px !important;
	}
	.mb-70-60-30 {
		margin-bottom: 60px !important;
	}
	.mb-65-55-25 {
		margin-bottom: 55px !important;
	}
	.mb-80-60-60 {
		margin-bottom: 60px !important;
	}
	.mb-80-40-40 {
		margin-bottom: 40px !important;
	}
	.pb-80-40-40 {
		padding-bottom: 40px !important;
	}
	.pt-80-40-40 {
		padding-top: 40px !important;
	}
	.mt-30-0-0 {
		margin-top: 0px !important;
	}
	.mt-40-0-0 {
		margin-top: 0px !important;
	}
	.pb-80-40-10 {
		padding-bottom: 40px !important;
	}
	.mb-70-10-0 {
		margin-bottom: 10px !important;
	}
	.pb-30-1-1 {
		padding-bottom: 1px !important;
	}
	.mb-90-60-40 {
		margin-bottom: 60px !important;
	}
	.mt-160-160-120 {
		margin-top: 160px !important;
	}
	.mt-80-80-40 {
		margin-top: 80px !important;
	}
	.mb-40-60-40 {
		margin-bottom: 60px !important;
	}
	.mb-60-20-0 {
		margin-bottom: 20px !important;
	}
	.mt-40-40-15 {
		margin-top: 40px !important;
	}
	.pt-80-60-40 {
		padding-top: 60px !important;
	}
	.pb-80-60-40 {
		padding-bottom: 60px !important;
	}
}

@media screen and (min-width: 992px) {
	/* computer, including small screens like laptops */
	.tablet-only {
		display: none !important;
	}
	.mobile-only {
		display: none !important;
	}
	.mobile-and-tablet-only {
		display: none !important;
	}
	.mb-80-40-20 {
		margin-bottom: 80px !important;
	}
	.mt-80-60-40 {
		margin-top: 80px !important;
	}
	.mt-120-60-40 {
		margin-top: 120px !important;
	}
	.mb-120-60-40 {
		margin-bottom: 120px !important;
	}
	.mb-120-60-40 {
		margin-bottom: 120px !important;
	}
	.mb-20-20-40 {
		margin-bottom: 20px !important;
	}
	.mb-80-35-15 {
		margin-bottom: 80px !important;
	}
	.mb-50-30-10 {
		margin-bottom: 50px !important;
	}
	.mt-65-45-25 {
		margin-top: 65px !important;
	}
	.mb-75-55-15 {
		margin-bottom: 75px !important;
	}
	.mt-75-55-35 {
		margin-top: 75px !important;
	}
	.mb-80-50-40 {
		margin-bottom: 80px !important;
	}
	.mb-80-60-40 {
		margin-bottom: 80px !important;
	}
	.mb-80-70-40 {
		margin-bottom: 80px !important;
	}
	.mt-80-70-40 {
		margin-top: 80px !important;
	}
	.ui.container.container-main {
		margin-top: 160px !important;
	}
	.mb-70-60-30 {
		margin-bottom: 70px !important;
	}
	.mb-65-55-25 {
		margin-bottom: 65px !important;
	}
	.mb-80-60-60 {
		margin-bottom: 80px !important;
	}
	.mb-80-40-40 {
		margin-bottom: 80px !important;
	}
	.pb-80-40-40 {
		padding-bottom: 80px !important;
	}
	.pt-80-40-40 {
		padding-top: 80px !important;
	}
	.mt-30-0-0 {
		margin-top: 30px !important;
	}
	.mt-40-0-0 {
		margin-top: 40px !important;
	}
	.pb-80-40-10 {
		padding-bottom: 40px !important;
	}
	.mb-70-10-0 {
		margin-bottom: 70px !important;
	}
	.pb-30-1-1 {
		padding-bottom: 30px !important;
	}
	.mw-600-computer {
		max-width: 600px;
	}
	.mb-90-60-40 {
		margin-bottom: 90px !important;
	}
	.mt-160-160-120 {
		margin-top: 160px !important;
	}
	.mt-80-80-40 {
		margin-top: 80px !important;
	}
	.mb-40-60-40 {
		margin-bottom: 40px !important;
	}
	.mb-60-20-0 {
		margin-bottom: 60px !important;
	}
	.mt-40-40-15 {
		margin-top: 40px !important;
	}
	.pt-80-60-40 {
		padding-top: 80px !important;
	}
	.pb-80-60-40 {
		padding-bottom: 80px !important;
	}
	.computer-button-center {
		display: flex; 
		flex-wrap: wrap; 
		justify-content: center;
	}
}

@media only screen and (max-width: 767px) {
	.div-grid-container {
		margin-left: -15px !important;
		margin-right: -15px !important;
	}
	.grid-row.mb-80-40-20 {
		margin-bottom: 0px !important;
	}
	.mb-5-mobile {
		margin-bottom: 5px !important;
	}
	.staff-icon-size.staff-bio {
		width: 345px;
		height: auto;
	}
	.hide-mobile {
		display: none !important;
	}
	.mt-80-40 {
		margin-top: 40px !important;
	}
	.mb-80-40 {
		margin-bottom: 40px !important;
	}
	.hidden-mobile {
		visibility: hidden;
	}
	.mb-80-60 {
		margin-bottom: 60px !important;
	}
	.mt-120-40 {
		margin-top: 40px !important;
	}
	.pb-120-40 {
		padding-bottom: 40px !important;
	}
	.pb-30-10 {
		padding-bottom: 10px !important;
	}
	.min-768px {
		display: none !important;
	}
	.footer-logo-text {
		max-width: 100%;
	}	
}

@media only screen and (min-width: 768px) {
	.mt-80-40 {
		margin-top: 80px !important;
	}
	.mb-80-40 {
		margin-bottom: 80px !important;
	}
	.mb-80-60 {
		margin-bottom: 80px !important;
	}
	.mt-120-40 {
		margin-top: 120px !important;
	}
	.pb-120-40 {
		padding-bottom: 120px !important;
	}
	.pb-30-10 {
		padding-bottom: 30px !important;
	}
	.hide-computer {
		display: none !important;
	}
	.max-767px {
		display: none !important;
	}
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
	.ui.container.container-main,
	.ui.container.container-header, 
	.ui.container.container-colored-section, 
	.ui.container.container-general {
		width: auto !important;
		min-width: 714px !important;
		margin-left: 40px !important;
		margin-right: 40px !important;
	}
} 
