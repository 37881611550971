body {
	margin: 0;
	font-family: neue-haas-unica, sans-serif !important;
	font-weight: 400 !important;
	font-style: normal !important;
	src: url(https://use.typekit.net/dat2hxo.css);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px !important;
}

h1,
h2,
h3,
h4,
h5,
p {
	line-height: 1.8em !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media screen and (max-width: 990px) {
	.page-title {
		margin-top: 20px !important;
	}
}

.ui.segment {
	margin: 0 !important;
}

.page-title {
	font-weight: 600;
	color: #294761;
	font-size: 2rem;
	margin-bottom: 25px;
	margin-top: 50px;
}